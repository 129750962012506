import React, { useState } from 'react'

export const AcceptBodyValidacionSumaBalanceComponent = (props) => {
    return (
        <>
            <div className="offcanvas-body">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="row mt-4 mb-4">
                                <div className="col-12 text-center">
                                    <span>
                                        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="36" cy="36" r="36" fill="#6FD65D" />
                                            <path d="M30.1793 43.4987L30.5337 43.8551L30.8882 43.4989L48.5951 25.7089L50.2949 27.4183L30.5338 47.2909L21.7064 38.4136L23.4206 36.7019L30.1793 43.4987Z" fill="white" stroke="white" stroke-linecap="round" />
                                        </svg>

                                    </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h5 className='fw-semibold'>Validación exitosa</h5>
                                    <h5 className='text-muted'>La suma de balance fue validada correctamente.</h5>
                                </div>
                            </div>
                            <div className="row mt-5 mb-5">
                                <div className="col-12 pb-2 text-center">
                                    <button className='btn btn-primary' data-bs-dismiss="offcanvas"
                                        aria-label="Close">Aceptar</button>
                                </div>
                                <div className="col-12 pt-2 text-center">
                                    <span className='text-muted btn' itemType='button' data-bs-dismiss="offcanvas" aria-label="Close">Cancelar</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
