import React from "react";
import {
  OffcanvasMantenedorBorrarIFRS,
  OffcanvasMantenedorIFRS,
} from "../../components/offcanvas/offcanvasMantenedor/OffcanvasMantenedorBorrarIFRS";
import { OffcanvasMantenedorCrearIFRS } from "../../components/offcanvas/offcanvasMantenedor/OffcanvasMantenedorCrearIFRS";
import { OffcanvasMantenedorEditarIFRS } from "../../components/offcanvas/offcanvasMantenedor/OffcanvasMantenedorEditarIFRS";
import { PageNavigation } from "../../components/pageNavigation/PageNavigation";
import { TableMantenedorIFRS } from "../../components/table/TableMantenedorIFRS";

export const MantenedorIFRS = () => {
  const IFRSs = [
    {
      codigo: "1100101000100000",
      descripcion: "Efectivo en empresas transportadoras en valores",
      tipoCuenta: "MB1",
      signoAsociado: "ambos +-",
    },
    {
      codigo: "1100101000200000",
      descripcion: "Efectivo en empresas transportadoras en valores",
      tipoCuenta: "MC1",
      signoAsociado: "+",
    },
    {
      codigo: "1100101000300000",
      descripcion: "Efectivo en empresas transportadoras en valores",
      tipoCuenta: "MR1",
      signoAsociado: "-",
    },
    {
      codigo: "4100101000100000",
      descripcion: "Efectivo en empresas transportadoras en valores",
      tipoCuenta: "MR1",
      signoAsociado: "+",
    },{
      codigo: "4100201000100000",
      descripcion: "Efectivo en empresas transportadoras en valores",
      tipoCuenta: "MB1",
      signoAsociado: "-",
    },
  ];
  return (
    <main className="container-fluid">
      <h1>Mantenedor de IFRS</h1>
      <TableMantenedorIFRS IFRSs={IFRSs} />
      <PageNavigation />
      <OffcanvasMantenedorCrearIFRS />
      <OffcanvasMantenedorEditarIFRS />
      <OffcanvasMantenedorBorrarIFRS />
    </main>
  );
};
