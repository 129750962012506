import React from "react";
import { TableMantenedorIFRSRow } from "./TableMantenedorIFRSRow";

export const TableMantenedorIFRS = (props) => {
  const {IFRSs} = props;
  return (
    <div>
      <table className="bi-table">
        <thead>
          <tr>
            <th>Código</th>
            <th>Descripción</th>
            <th className="text-center">Tipo de cuenta</th>
            <th className="text-center">Signo asociado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {
            IFRSs.map((IFRS, index) => (
              <TableMantenedorIFRSRow IFRS={IFRS} key={index}/>
            ))
          }
          
        </tbody>
      </table>
      <div className="d-flex justify-content-end">
        <button
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasMantenedorCrearIFRS"
          aria-controls="offcanvasRight"
          className="btn btn-outline-primary"
        >
          Crear IFRS
        </button>
      </div>
    </div>
  );
};
