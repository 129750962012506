import React from "react";

export const TableReportesMB1 = () => {
  const reportes = [
    "0009MB1202210",
    "10000000004598864643206+02203616589537+01932296374194+00000000000000+00462951679475+",
    "10500000000157875180970+00043668489000+00000000000000+00000000000000+00114206691970+",
    "10500010000005553601586+00003122486189+00000000000000+00000000000000+00002431115397+",
    "10500010100005379551586+00002948436189+00000000000000+00000000000000+00002431115397+",
    "10500010200000174050000+00000174050000+00000000000000+00000000000000+00000000000000+",
    "10500010300000000000000+00000000000000+00000000000000+00000000000000+00000000000000+",
    "10500020000132666811511+00037161006227+00000000000000+00000000000000+00095505805284+",
    "10500020100010191811511+00009161006227+00000000000000+00000000000000+00001030805284+",
    "10500020200094475000000+00000000000000+00000000000000+00000000000000+00094475000000+",
    "10500020900028000000000+00028000000000+00000000000000+00000000000000+00000000000000+",
    "10500030000000000000000+00000000000000+00000000000000+00000000000000+00000000000000+",
    "10500030100000000000000+00000000000000+00000000000000+00000000000000+00000000000000+",
    "10500030200000000000000+00000000000000+00000000000000+00000000000000+00000000000000+",
    "10500030900000000000000+00000000000000+00000000000000+00000000000000+00000000000000+",
    "10500040000003845946411+00003384996584+00000000000000+00000000000000+00000460949827+",
    "10500050000015808821462+00000000000000+00000000000000+00000000000000+00015808821462+",
    "10700000000118009429407+00059105279943+00000000000000+00000000000000+00058904149464+",
    "10700010000002291043934+00001145982635+00000000000000+00000000000000+00001145061299+",
    "10700010100000000000000+00000000000000+00000000000000+00000000000000+00000000000000+",
    "10700010200001966500844+00001145982635+00000000000000+00000000000000+00000820518209+",
    "10700010300000324543090+00000000000000+00000000000000+00000000000000+00000324543090+",
    "10700020000000000000000+00000000000000+00000000000000+00000000000000+00000000000000+",
    "10700020100000000000000+00000000000000+00000000000000+00000000000000+00000000000000+",
    "10700020200000000000000+00000000000000+00000000000000+00000000000000+00000000000000+",
    "10700030000000000000000+00000000000000+00000000000000+00000000000000+00000000000000+",
    "10700040000115718385473+00057959297308+00000000000000+00000000000000+00057759088165+",
    "10700040100031671990521+00031671990521+00000000000000+00000000000000+00000000000000+",
    "10700040900037588675665+00000000000000+00000000000000+00000000000000+00037588675665+",
    "10700041100017827385982+00017827385982+00000000000000+00000000000000+00000000000000+",
    "10700041900028630333305+00008459920805+00000000000000+00000000000000+00020170412500+",
    "11000000000580194987231+00436658818837+00143536168394+00000000000000+00000000000000+",
    "11100010000559609121369+00416072952975+00143536168394+00000000000000+00000000000000+",
    "11100010100089789546582+00089789546582+00000000000000+00000000000000+00000000000000+",
    "11100010200469819574787+00326283406393+00143536168394+00000000000000+00000000000000+v",
  ];
  return (
    <div className="table-reportes">
      <div className="table-reportes-id">
        {reportes.map((reporte) => ( <p> {reporte}</p>))}
      </div>
      <div className="table-reportes-numero">
        <span>Caracteres</span>
        {reportes.map((reporte) => ( <span>{reporte.length}</span>))}
      </div>
    </div>
  );
};
