import React from 'react'
import { ProgressBar } from 'react-bootstrap';

export const BodyCargaBalancesComponent = (props) => {

    const [ file,setFile ] = props.fileEdit;

    return (
        <div className="offcanvas-body">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="row">
                            <div className="col-12 p-3">
                                <div className="row">
                                    <div className="col-2 text-center">
                                        <svg width="33" height="38" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M22.8555 9.716C22.8409 9.58739 22.8127 9.46068 22.7713 9.338V9.212C22.7039 9.06805 22.614 8.93573 22.5049 8.82L14.0908 0.42C13.9749 0.311103 13.8424 0.221313 13.6982 0.154C13.6563 0.148064 13.6138 0.148064 13.572 0.154C13.4295 0.0724377 13.2722 0.0200819 13.1092 0H4.625C3.50923 0 2.43915 0.442499 1.65018 1.23015C0.861208 2.0178 0.417969 3.08609 0.417969 4.2V23.8C0.417969 24.9139 0.861208 25.9822 1.65018 26.7698C2.43915 27.5575 3.50923 28 4.625 28H18.6484C19.7642 28 20.8343 27.5575 21.6233 26.7698C22.4122 25.9822 22.8555 24.9139 22.8555 23.8V9.8C22.8555 9.8 22.8555 9.8 22.8555 9.716ZM14.4414 4.774L18.0735 8.4H15.8438C15.4718 8.4 15.1151 8.2525 14.8521 7.98995C14.5892 7.7274 14.4414 7.3713 14.4414 7V4.774ZM20.0508 23.8C20.0508 24.1713 19.903 24.5274 19.64 24.79C19.3771 25.0525 19.0204 25.2 18.6484 25.2H4.625C4.25308 25.2 3.89638 25.0525 3.63339 24.79C3.3704 24.5274 3.22266 24.1713 3.22266 23.8V4.2C3.22266 3.8287 3.3704 3.4726 3.63339 3.21005C3.89638 2.9475 4.25308 2.8 4.625 2.8H11.6367V7C11.6367 8.11391 12.08 9.1822 12.8689 9.96985C13.6579 10.7575 14.728 11.2 15.8438 11.2H20.0508V23.8ZM14.8481 14.406L10.2344 19.026L8.42535 17.206C8.16129 16.9424 7.80313 16.7943 7.42969 16.7943C7.05624 16.7943 6.69809 16.9424 6.43402 17.206C6.16996 17.4696 6.02161 17.8272 6.02161 18.2C6.02161 18.5728 6.16996 18.9304 6.43402 19.194L9.23871 21.994C9.36908 22.1252 9.52418 22.2294 9.69507 22.3004C9.86596 22.3715 10.0492 22.4081 10.2344 22.4081C10.4195 22.4081 10.6028 22.3715 10.7737 22.3004C10.9446 22.2294 11.0997 22.1252 11.23 21.994L16.8394 16.394C17.1035 16.1304 17.2518 15.7728 17.2518 15.4C17.2518 15.0272 17.1035 14.6696 16.8394 14.406C16.5753 14.1424 16.2172 13.9943 15.8438 13.9943C15.4703 13.9943 15.1122 14.1424 14.8481 14.406Z" fill="#0042CC" />
                                        </svg>
                                    </div>
                                    <div className="col-8">
                                        <div className="row ">
                                            <div className="col text-center">
                                                <p className='fw-semibold'> {file.name} </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col text-center">
                                                <ProgressBar now={file.progress} animated={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        {file.progress}%
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row p-2">
                            <div className="col-6">
                                <form className='form-floating'>
                                    <select className='form-control' name="year" id="yearconsolidacion" disabled defaultValue="2021">
                                        <option value="2021">2021</option>
                                    </select>
                                    <label htmlFor="yearconsolidacion">Año</label>
                                </form>
                            </div>
                            <div className="col-6">
                                <form action="" className='form-floating'>
                                    <select className='form-control' name="month" id="monthconsolidacion" disabled defaultValue="octubre">
                                        <option value="octubre">Octubre</option>
                                    </select>
                                    <label htmlFor="monthconsolidacion">Mes</label>
                                </form>
                            </div>
                        </div>
                        <div className="row p-2">
                            <div className="col-12">
                                <form action="" className='form-floating'>
                                    <select className='form-control' name="business" id="businessconsolidacion" disabled defaultValue="banco">
                                        <option value="banco">Banco</option>
                                    </select>
                                    <label htmlFor="businessconsolidacion">Empresa</label>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row p-5">
                    <div className="col ">
                        <a
                            href="#"
                            className="btn btn-secondary"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        >
                            Cancelar
                        </a>
                    </div>
                    <div className="col ms-auto">
                        <a
                            href="#"
                            className="btn btn-danger"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        >
                            Guardar
                        </a>
                    </div>

                </div>
            </div>
        </div>
    )
}
