import React from "react";
import { Table } from "react-bootstrap";

export const AjusteCuadraturaPesosComponent = (props) => {
  const [step, setStep] = props.editStep
  const stepAjuste = {
    id: 4,
    name: 'Ajuste de cuadratura de pesos',
    idHtml: 'ajuste',
  }
  const bussines = [
    {
      financialData: {
        100000000: [
          2203880600846, 1932296374194, 0, 462951679475, 4599128654515,
        ],
        105000000: [43318779162, 0, 0, 654465, 9191],
        105000100: [132123, 0, 0, 343434, 0],
        105000101: [0, 0, 12412124556, 0, 0],
        105000102: [0, 0, 124121246, 5151515, 0],
        105000103: [0, 0, 1241212456, 0, 0],
        105000200: [181818494949, 0, 12416, 0, 0],
        105000201: [0, 0, 1241212456, 0, 0],
        105000202: [0, 0, 1241212456, 0, 818181],
        105000209: [0, 818181, 1241212456, 0, 0],
        105000300: [0, 0, 1241212456, 0, 81959595],
        105000301: [0, 0, 0, 0, 0],
        105000302: [0, 0, 0, 0, 0],
        105000309: [0, 0, 0, 0, 0],
        105000400: [3035486746, 0, 0, 460949827, 3496436573],
        105000500: [0, 0, 0, 15808821462, 15808821462],
        107000000: [59105279943, 0, 0, 58904149464, 118009429407],
        107000100: [1145982635, 0, 0, 1145061299, 2291043934],
        107000101: [0, 0, 0, 0, 0],
        107000102: [1145982635, 0, 0, 820518209, 1966500844],
        107000103: [0, 0, 0, 324543090, 324543090],
      },
    },
  ];
  const cuadraturas = [
    {
      100000000: 0,
      105000000: -1,
      105000100: 0,
      105000101: 0,
      105000102: 0,
      105000103: 0,
      105000200: 0,
      105000201: 0,
      105000202: 0,
      105000209: 0,
      105000300: 0,
      105000301: 0,
      105000302: 0,
      105000309: 0,
      105000400: 0,
      105000500: 0,
      107000000: 0,
      107000100: 0,
      107000101: 0,
      107000102: 0,
      107000103: 0,
    },
  ];

  const tableItems = [
    {
      rubro: "10000",
      linea: "00",
      item: "00",
      codigo: "100000000",
      descripcion: "TOTAL ACTIVOS",
    },
    {
      rubro: "10500",
      linea: "00",
      item: "00",
      codigo: "105000000",
      descripcion: "EFECTIVO Y DEPÓSITOS EN BANCOS",
    },
    {
      rubro: "10500",
      linea: "01",
      item: "00",
      codigo: "105000100",
      descripcion: "Efectivo",
    },
    {
      rubro: "10500",
      linea: "01",
      item: "01",
      codigoPadre: "105000100",
      codigo: "105000101",
      descripcion: "Efectivo en oficinas",
    },
    {
      rubro: "10500",
      linea: "01",
      item: "02",
      codigoPadre: "105000100",
      codigo: "105000102",
      descripcion: "Efectivo en empresas transportadoras de valores",
    },
    {
      rubro: "10500",
      linea: "01",
      item: "03",
      codigoPadre: "105000100",
      codigo: "105000103",
      descripcion: "Efectivo en custodia en otras entidades financieras",
    },
    {
      rubro: "10500",
      linea: "02",
      item: "03",
      codigo: "105000200",
      descripcion: "Depósitos en el Banco Central de Chile",
    },
    {
      rubro: "10500",
      linea: "02",
      item: "01",
      codigoPadre: "105000200",
      codigo: "105000201",
      descripcion: "Depósitos en cuenta corriente",
    },
    {
      rubro: "10500",
      linea: "02",
      item: "02",
      codigoPadre: "105000200",
      codigo: "105000202",
      descripcion: "Depósitos overnight",
    },
    {
      rubro: "10500",
      linea: "02",
      item: "09",
      codigoPadre: "105000200",
      codigo: "105000209",
      descripcion: "Otros depósitos disponibles",
    },
    {
      rubro: "10500",
      linea: "03",
      item: "00",
      codigo: "105000300",
      descripcion: "Depósitos en el Bancos Centrales del exterior",
    },
    {
      rubro: "10500",
      linea: "03",
      item: "01",
      codigoPadre: "105000300",
      codigo: "105000301",
      descripcion: "Depósitos en cuenta corriente",
    },
    {
      rubro: "10500",
      linea: "03",
      item: "02",
      codigoPadre: "105000300",
      codigo: "105000302",
      descripcion: "Depósitos overnight",
    },
    {
      rubro: "10500",
      linea: "03",
      item: "09",
      codigoPadre: "105000300",
      codigo: "105000309",
      descripcion: "Otros depósitos disponibles",
    },
    {
      rubro: "10500",
      linea: "04",
      item: "00",
      codigo: "105000400",
      descripcion: "Depósitos en bancos del país",
    },
    {
      rubro: "10500",
      linea: "05",
      item: "00",
      codigo: "105000500",
      descripcion: "Depósitos en bancos del exterior",
    },
    {
      rubro: "10700",
      linea: "00",
      item: "00",
      codigo: "107000000",
      descripcion: "OPERACIONES CON LA LIQUIDACIÓN EN CURSO",
    },
    {
      rubro: "10700",
      linea: "01",
      item: "00",
      codigo: "107000100",
      descripcion: "Documentos a cargo de otros bancos (canje)",
    },
    {
      rubro: "10700",
      linea: "03",
      item: "01",
      codigoPadre: "107000100",
      codigo: "107000101",
      descripcion: "Canje no deducible",
    },
    {
      rubro: "10700",
      linea: "03",
      item: "02",
      codigoPadre: "107000100",
      codigo: "107000102",
      descripcion: "Canje de la plaza",
    },
    {
      rubro: "10700",
      linea: "03",
      item: "09",
      codigoPadre: "107000100",
      codigo: "107000103",
      descripcion: "Canje de otras plazas",
    },
  ];

  const spaceTab = "\u00A0\u00A0\u00A0\u00A0";
  const itemsCodigo = [];
  tableItems.map((item, index) => {
    itemsCodigo.push(item.codigo);
  });
  return (
    <>
      <div className="row pt-5">
        <div className="col-5">
          <Table striped hover className="border-shadow">
            <thead>
              <tr>
                <th>Rubro</th>
                <th>Línea</th>
                <th>ítem</th>
                <th>Código</th>
                <th>Descripción del código</th>
              </tr>
            </thead>
            <tbody>
              {tableItems.map((item, index) => {
                const style =
                  index == 0
                    ? "text-turquoise"
                    : index == 1 || index == 16
                    ? "text-blue"
                    : "";
                return (
                  <tr key={index}>
                    <td className={style}>{item.rubro}</td>
                    <td className={style}>{item.linea}</td>
                    <td className={style}>{item.item}</td>
                    <td className={style}>{item.codigo}</td>
                    <td
                      className={
                        !item.codigoPadre ? "fw-bold " + style : "" + style
                      }
                    >
                      {item.codigoPadre
                        ? spaceTab + item.descripcion
                        : item.descripcion}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className="col-7">
          <div className="horizontal-scroll">
            {bussines.map((itemBussines, index) => {
              return (
                <>
                  <div className="display-inline" key={index}>
                    <div className="col-11 border-shadow">
                      <Table striped hover className="ps-1 pe-1">
                        <thead className="border-top-red">
                          <tr>
                            <th>MChnR</th>
                            <th>MR(IPC)</th>
                            <th>MR(TC)</th>
                            <th>ME</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {itemsCodigo.map((financeItem, index) => {
                            const style =
                              index == 0
                                ? "text-turquoise"
                                : index == 1 || index == 16
                                ? "text-blue"
                                : "";
                            return (
                              <tr key={financeItem}>
                                {itemBussines.financialData[
                                  "" + financeItem
                                ].map((valueItem, index) => {
                                  return (
                                    <td
                                      className={style}
                                      key={financeItem + index}
                                    >
                                      {valueItem}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </>
              );
            })}
            {cuadraturas.map((itemCuadratura, index) => {
              return (
                <>
                  <div className="display-inline" key={index}>
                    <div className="col-6 border-shadow">
                      <Table striped hover className="ps-1 pe-1">
                        <thead className="border-top-blue">
                          <tr>
                            <th className="text-center">Cuadratura Pesos</th>
                          </tr>
                        </thead>
                        <tbody>
                          {itemsCodigo.map((financeCode, index) => {
                            const style =
                              index == 0
                                ? "text-turquoise text-center"
                                : index == 1 || index == 16
                                ? "text-blue text-center"
                                : " text-center";
                            return (
                              <tr key={financeCode}>
                                <td className={style} key={financeCode + index}>
                                  {itemCuadratura[financeCode] == 0 ? (
                                    itemCuadratura[financeCode]
                                  ) : (
                                    <p className="text-red fw-bolder position-relative">
                                      {itemCuadratura[financeCode]}
                                      <button
                                        className="btn position-absolute end-0 top-50 translate-middle"
                                        type="button"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasConsolidacion"
                                        aria-controls="offcanvasConsolidacion"
                                        onClick={ () => { setStep(stepAjuste) }}
                                      >
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M15.5 4.43001C15.5006 4.3313 15.4816 4.23345 15.4443 4.14208C15.407 4.0507 15.352 3.96759 15.2825 3.89751L12.1025 0.717512C12.0324 0.648001 11.9493 0.593007 11.8579 0.555684C11.7665 0.518361 11.6687 0.499442 11.57 0.500013C11.4713 0.499442 11.3734 0.518361 11.2821 0.555684C11.1907 0.593007 11.1076 0.648001 11.0375 0.717512L8.915 2.84001L0.717512 11.0375C0.648001 11.1076 0.593007 11.1907 0.555684 11.2821C0.518361 11.3734 0.499442 11.4713 0.500013 11.57V14.75C0.500013 14.9489 0.57903 15.1397 0.719682 15.2803C0.860334 15.421 1.0511 15.5 1.25001 15.5H4.43001C4.53495 15.5057 4.63993 15.4893 4.73812 15.4518C4.83631 15.4143 4.92554 15.3567 5 15.2825L13.1525 7.085L15.2825 5C15.3509 4.92732 15.4067 4.84365 15.4475 4.75251C15.4547 4.69272 15.4547 4.63229 15.4475 4.57251C15.451 4.53759 15.451 4.50242 15.4475 4.46751L15.5 4.43001ZM4.12251 14H2.00001V11.8775L9.4475 4.43001L11.57 6.5525L4.12251 14ZM12.6275 5.495L10.505 3.37251L11.57 2.31501L13.685 4.43001L12.6275 5.495Z"
                                            fill="#D90D39"
                                          />
                                        </svg>
                                      </button>
                                    </p>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
