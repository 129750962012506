import React from 'react'
import { Link } from 'react-router-dom'
import './steptabnavstyle.css'
export const StepTabnavReportesComponent = (props) => {
    const { stages } = props
    return (
        <>
            <div className="row">
                <div className="col-10">
                    <ul className="nav">
                        {
                            stages.map((stage) => {
                                return (
                                    <li className="nav-item" key={stage.id}>
                                        <Link className={`nav-link fw-semibold ${stage.id==1?'active':''}`} to={`#${stage.idHtml}`} data-bs-toggle="tab">{stage.name}</Link>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </>
    )
}
