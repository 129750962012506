import React from 'react'
import { Table } from 'react-bootstrap'

export const ValidacionSumaBalancesComponent = (props) => {
    const { tableItems } = props
    const { bussines } = props

    const spaceTab = '\u00A0\u00A0\u00A0\u00A0'
    // console.info("tableItems", tableItems);
    // console.info("negocios", bussines);
    const itemsCodigo = []
    tableItems.map((item, index) => {
        itemsCodigo.push(
            item.codigo
        )
    })
    return (
        <>
            <div className="row pt-4">
                <div className="col-5">
                    <h5 style={
                        {
                            visibility: 'hidden',
                        }
                    }>-</h5>
                    <Table striped hover className='border-shadow'>
                        <thead>
                            <tr>
                                <th>Rubro</th>
                                <th>Línea</th>
                                <th>ítem</th>
                                <th>Código</th>
                                <th>Descripción del código</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tableItems.map((item, index) => {
                                    const style = index == 0 ? 'text-turquoise' : index == 1 ? 'text-blue' : ''
                                    return (
                                        <tr key={index}>
                                            <td className={style}>{item.rubro}</td>
                                            <td className={style}>{item.linea}</td>
                                            <td className={style}>{item.item}</td>
                                            <td className={style}>{item.codigo}</td>
                                            <td className={!item.codigoPadre ? 'fw-bold ' + style : '' + style} >{item.codigoPadre ? spaceTab + item.descripcion : item.descripcion}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
                <div className="col-7">
                    <div className='horizontal-scroll'>
                        {
                            bussines.map((itemBussines, index) => {
                                return <>
                                    <div className="display-inline" key={index}>
                                        <h5 className="text-center text-red">{itemBussines.name} ({itemBussines.month} {itemBussines.year})</h5>
                                        <div className="col-11 border-shadow">
                                            <Table striped hover className='ps-1 pe-1'>
                                                <thead className='border-top-red '>
                                                    <tr>
                                                        <th>MChnR</th>
                                                        <th>MR(IPC)</th>
                                                        <th>MR(TC)</th>
                                                        <th>ME</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        itemsCodigo.map((financeItem, index) => {
                                                            const style = index == 0 ? 'text-turquoise' : index == 1 ? 'text-blue' : ''
                                                            return <tr key={financeItem}>
                                                                {
                                                                    itemBussines.financialData['' + financeItem].map((valueItem, index) => {
                                                                        return <td className={style} key={financeItem+index} >{valueItem}</td>
                                                                    })
                                                                }
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </>
                            })
                        }
                    </div>
                </div>

            </div>
        </>
    )
}
