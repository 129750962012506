import React, { useId, useState } from 'react'
import { BalanceConsolidadoComponent } from '../../components/consolidacionComponents/fifthStep/BalanceConsolidadoComponent'
import { CargaDeBalancesComponent } from '../../components/consolidacionComponents/firstStep/CargaDeBalancesComponent'
import { AjusteCuadraturaPesosComponent } from '../../components/consolidacionComponents/fourthStep/AjusteCuadraturaPesosComponent'
import { ValidacionSumaBalancesComponent } from '../../components/consolidacionComponents/secondStep/ValidacionSumaBalancesComponent'
import { AsientosConsolidacionComponent } from '../../components/consolidacionComponents/thirdStep/AsientosConsolidacionComponent'
import { OffcanvasConsolidacionComponent } from '../../components/offcanvas/offcanvasConsolidacion/OffcanvasConsolidacionComponent'
import { StepTabnavConsolidadosComponent } from '../../components/StepTabnavConsolidados/StepTabnavConsolidadosComponent'

export const ConsolidacionPageComponent = () => {
    const [fileXls, setFileXls] = useState({
        file: 'algo',
        name: 'archivo_octubre.xls',
        progress: 75
    })

    const [step, setStep] = useState({
        id: 1,
        name: 'Carga de balances',
        idHtml: 'carga'
    })

    const stages = [
        {
            id: 1,
            name: 'Carga de balances',
            idHtml: 'carga'
        },
        {
            id: 2,
            name: 'Validación suma de balances',
            idHtml: 'validacion',
        },
        {
            id: 3,
            name: 'Asientos de consolidacion',
            idHtml: 'asientos',
        },
        {
            id: 4,
            name: 'Ajuste de cuadratura de pesos',
            idHtml: 'ajuste',
        },
        {
            id: 5,
            name: 'Balance consolidado',
            idHtml: 'balance',
        }
    ]
    const bussines = [
        {
            name: 'Banco',
            month: 'Octubre',
            year: '2022',
            financialData:{
                '100000000': [2203880600846,1932296374194,0, 462951679475, 4599128654515],
                '105000000': [43318779162,0,0,654465,9191],
                '105000100': [132123,0,0,343434,0],
                '105000101': [0,0,12412124556,0,0],
                '105000102': [0,0,124121246,5151515,0],
                '105000103': [0,0,1241212456,0,0],
                '105000200': [181818494949,0,12416,0,0],
                '105000201': [0,0,1241212456,0,0],
                '105000202': [0,0,1241212456,0,818181],
                '105000209': [0,818181,1241212456,0,0],
                '105000300': [0,0,1241212456,0,81959595],
                '105000301': [0,0,1241212456,0,323232],

            }
        },
        {
            name: 'AGF',
            month: 'Octubre',
            year: '2022',
            financialData:{
                '100000000': [2203880600846,1932296374194,0, 462951679475, 4599128654515],
                '105000000': [43318779162,0,0,654465,9191],
                '105000100': [132123,0,0,343434,0],
                '105000101': [0,0,12412124556,0,0],
                '105000102': [0,0,124121246,5151515,0],
                '105000103': [0,0,1241212456,0,0],
                '105000200': [181818494949,0,12416,0,0],
                '105000201': [0,0,1241212456,0,0],
                '105000202': [0,0,1241212456,0,818181],
                '105000209': [0,818181,1241212456,0,0],
                '105000300': [0,0,1241212456,0,81959595],
                '105000301': [0,0,1241212456,0,323232],

            }
        },
        {
            name: 'CS',
            month: 'Octubre',
            year: '2022',
            financialData:{
                '100000000': [2203880600846,1932296374194,0, 462951679475, 4599128654515],
                '105000000': [43318779162,0,0,654465,9191],
                '105000100': [132123,0,0,343434,0],
                '105000101': [0,0,12412124556,0,0],
                '105000102': [0,0,124121246,5151515,0],
                '105000103': [0,0,1241212456,0,0],
                '105000200': [181818494949,0,12416,0,0],
                '105000201': [0,0,1241212456,0,0],
                '105000202': [0,0,1241212456,0,818181],
                '105000209': [0,818181,1241212456,0,0],
                '105000300': [0,0,1241212456,0,81959595],
                '105000301': [0,0,1241212456,0,323232],

            }
        },
        {
            name: 'Suma de balances indiv.',
            month: 'Octubre',
            year: '2022',
            financialData:{
                '100000000': [2203880600846,1932296374194,0, 462951679475, 4599128654515],
                '105000000': [43318779162,0,0,654465,9191],
                '105000100': [132123,0,0,343434,0],
                '105000101': [0,0,12412124556,0,0],
                '105000102': [0,0,124121246,5151515,0],
                '105000103': [0,0,1241212456,0,0],
                '105000200': [181818494949,0,12416,0,0],
                '105000201': [0,0,1241212456,0,0],
                '105000202': [0,0,1241212456,0,818181],
                '105000209': [0,818181,1241212456,0,0],
                '105000300': [0,0,1241212456,0,81959595],
                '105000301': [0,0,1241212456,0,323232],

            }
        }
    ]
    const tableItems = [
        {
            rubro: '1000',
            linea: '00',
            item: '00',
            codigo: '100000000',
            descripcion: 'TOTAL ACTIVOS',
        },
        {
            rubro: '10500',
            linea: '00',
            item: '00',
            codigo: '105000000',
            descripcion: 'EFECTIVO Y DEPÓSITOS EN BANCOS',
        },
        {
            rubro: '10500',
            linea: '01',
            item: '00',
            codigo: '105000100',
            descripcion: 'Efectivo',
        },
        {
            rubro: '10500',
            linea: '01',
            item: '01',
            codigoPadre: '105000100',
            codigo: '105000101',
            descripcion: 'Efectivo en oficinas',
        },
        {
            rubro: '10500',
            linea: '01',
            item: '02',
            codigoPadre: '105000100',
            codigo: '105000102',
            descripcion: 'Efectivo en empresas transportadoras de valores',
        },
        {
            rubro: '10500',
            linea: '01',
            item: '03',
            codigoPadre: '105000100',
            codigo: '105000103',
            descripcion: 'Efectivo en custodia en otras entidades financieras',
        },
        {
            rubro: '10500',
            linea: '02',
            item: '00',
            codigo: '105000200',
            descripcion: 'Depósitos en el Banco Central de Chile',
        },
        {
            rubro: '10500',
            linea: '02',
            item: '01',
            codigoPadre: '105000200',
            codigo: '105000201',
            descripcion: 'Depósitos en cuenta corriente'
        },
        {
            rubro: '10500',
            linea: '02',
            item: '02',
            codigoPadre: '105000200',
            codigo: '105000202',
            descripcion: 'Depósitos overnight'
        },
        {
            rubro: '10500',
            linea: '02',
            item: '09',
            codigoPadre: '105000200',
            codigo: '105000209',
            descripcion: 'Otros depósitos disponibles'
        },
        {
            rubro: '10500',
            linea: '03',
            item: '00',
            codigo: '105000300',
            descripcion: 'Depósitos en el Bancos Centrales del exterior'
        },
        {
            rubro: '10500',
            linea: '03',
            item: '01',
            codigoPadre: '105000300',
            codigo: '105000301',
            descripcion: 'Depósitos en cuenta corriente'
        }
    ]
    return (
        <>
            <div className="container-fluid mb-5">
                <div className="row">
                    <div className="col-12 pt-5 pb-5">
                        <h1>Consolidacion</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <StepTabnavConsolidadosComponent stages={stages}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="carga">
                                <CargaDeBalancesComponent fileEdit={[fileXls, setFileXls]} />
                            </div>
                            <div className="tab-pane fade" id="validacion">
                                <ValidacionSumaBalancesComponent tableItems={tableItems} bussines={bussines} />
                                <div className="row justify-content-end mt-5 pt-2 pb-2 bg-blue">
                                    <div className="col-auto">
                                        <p className='text-white fw-semibold pt-2'>¿Desea validar sumatoria?</p>
                                    </div>
                                    <div className="col-auto">
                                        <button className="btn btn-danger" data-bs-toggle="offcanvas" data-bs-target="#offcanvasConsolidacion" aria-controls="offcanvasConsolidacion" onClick={()=>setStep(stages[1])}>Validar</button>
                                    </div>
                                    <div className="col-auto">
                                        <button className="btn btn-primary">Rechazar</button>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="asientos">
                                <AsientosConsolidacionComponent/>
                            </div>
                            <div className="tab-pane fade" id="ajuste">
                                <AjusteCuadraturaPesosComponent tableItems={tableItems} bussines={bussines} editStep={[step, setStep]} />
                            </div>
                            <div className="tab-pane fade" id="balance">
                                <BalanceConsolidadoComponent tableItems={tableItems} bussines={bussines} editStep={[step, setStep]} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OffcanvasConsolidacionComponent fileEdit={[fileXls, setFileXls]} stage={step} />  
        </>
    )
}
