import React from "react";
import { StepTabnavReportesComponent } from "../../components/StepTabnabReportes/StepTabnabReportesComponent";
import { TableReportesMB1 } from "../../components/table/TableReportesMB1";
import { TableReportesMC1 } from "../../components/table/TableReportesMC1";
import { TableReportesMR1 } from "../../components/table/TableReportesMR1";

export const ReportesPageComponent = () => {
  const stages = [
    {
      id: 1,
      name: "Archivo normativo: MB1",
      idHtml: "archivoNomartivoMB1",
      compoment: <TableReportesMB1/>
    },
    {
      id: 2,
      name: "Archivos normativo: MR1",
      idHtml: "archivoNomartivoMR1",
    },
    {
      id: 3,
      name: "Archivos normativo: MC1",
      idHtml: "archivoNomartivoMC1",
    },
  ];
  return (
    <main>
      <div className="container-fluid">
        <h1>Consolidacion</h1>
        <div className="row">
          <div className="col-12">
            <StepTabnavReportesComponent stages={stages} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="tab-content">
              <div className="tab-pane fade show active" id="archivoNomartivoMB1">
                <TableReportesMB1/>
              </div>
              <div className="tab-pane fade" id="archivoNomartivoMR1">
                <TableReportesMC1/>
              </div>
              <div className="tab-pane fade" id="archivoNomartivoMC1">
                <TableReportesMR1/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
