import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: {
        name: null,
        email: null,
        photo: null,
        isLogged: true
    }
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        login: (state, action) => {
            state.user = action.payload;
        },
        logout: (state) => {
            state.user = null;
        }
    }
})

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;