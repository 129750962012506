import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { OffcanvasValidarAsientoConsolidacionComponent } from "../../../components/offcanvas/offcanvasConsolidacion/ValidarAsientoConsolidacion/OffcanvasValidarAsientoConsolidacionComponent";

export const AsientosConsolidacionVistaPreviaComponent = (props) => {
  let { tableItems } = props;
  let { bussines } = props;
  bussines = [
    {
      name: "Banco",
      month: "Octubre",
      year: "2022",
      financialData: {
        100000000: [
          2203880600846, 1932296374194, 0, 462951679475, 4599128654515,
        ],
        105000000: [43318779162, 0, 0, 654465, 9191],
        105000100: [132123, 0, 0, 343434, 0],
        105000101: [0, 0, 12412124556, 0, 0],
        105000102: [0, 0, 124121246, 5151515, 0],
        105000103: [0, 0, 1241212456, 0, 0],
        105000200: [181818494949, 0, 12416, 0, 0],
        105000201: [0, 0, 1241212456, 0, 0],
        105000202: [0, 0, 1241212456, 0, 818181],
        105000209: [0, 818181, 1241212456, 0, 0],
        105000300: [0, 0, 1241212456, 0, 81959595],
        105000301: [0, 0, 0, 0, 0],
        105000302: [0, 0, 0, 0, 0],
        105000309: [0, 0, 0, 0, 0],
        105000400: [3035486746, 0, 0, 460949827, 3496436573],
        105000500: [0, 0, 0, 15808821462, 15808821462],
        107000000: [59105279943, 0, 0, 58904149464, 118009429407],
        107000100: [1145982635, 0, 0, 1145061299, 2291043934],
        107000101: [0, 0, 0, 0, 0],
        107000102: [1145982635, 0, 0, 820518209, 1966500844],
        107000103: [0, 0, 0, 324543090, 324543090],
      },
    },
    {
      name: "AGF",
      month: "Octubre",
      year: "2022",
      financialData: {
        100000000: [
          2203880600846, 1932296374194, 0, 462951679475, 4599128654515,
        ],
        105000000: [43318779162, 0, 0, 654465, 9191],
        105000100: [132123, 0, 0, 343434, 0],
        105000101: [0, 0, 12412124556, 0, 0],
        105000102: [0, 0, 124121246, 5151515, 0],
        105000103: [0, 0, 1241212456, 0, 0],
        105000200: [181818494949, 0, 12416, 0, 0],
        105000201: [0, 0, 1241212456, 0, 0],
        105000202: [0, 0, 1241212456, 0, 818181],
        105000209: [0, 818181, 1241212456, 0, 0],
        105000300: [0, 0, 1241212456, 0, 81959595],
        105000301: [0, 0, 0, 0, 0],
        105000302: [0, 0, 0, 0, 0],
        105000309: [0, 0, 0, 0, 0],
        105000400: [3035486746, 0, 0, 460949827, 3496436573],
        105000500: [0, 0, 0, 15808821462, 15808821462],
        107000000: [59105279943, 0, 0, 58904149464, 118009429407],
        107000100: [1145982635, 0, 0, 1145061299, 2291043934],
        107000101: [0, 0, 0, 0, 0],
        107000102: [1145982635, 0, 0, 820518209, 1966500844],
        107000103: [0, 0, 0, 324543090, 324543090],
      },
    },
    {
      name: "CS",
      month: "Octubre",
      year: "2022",
      financialData: {
        100000000: [
          2203880600846, 1932296374194, 0, 462951679475, 4599128654515,
        ],
        105000000: [43318779162, 0, 0, 654465, 9191],
        105000100: [132123, 0, 0, 343434, 0],
        105000101: [0, 0, 12412124556, 0, 0],
        105000102: [0, 0, 124121246, 5151515, 0],
        105000103: [0, 0, 1241212456, 0, 0],
        105000200: [181818494949, 0, 12416, 0, 0],
        105000201: [0, 0, 1241212456, 0, 0],
        105000202: [0, 0, 1241212456, 0, 818181],
        105000209: [0, 818181, 1241212456, 0, 0],
        105000300: [0, 0, 1241212456, 0, 81959595],
        105000301: [0, 0, 0, 0, 0],
        105000302: [0, 0, 0, 0, 0],
        105000309: [0, 0, 0, 0, 0],
        105000400: [3035486746, 0, 0, 460949827, 3496436573],
        105000500: [0, 0, 0, 15808821462, 15808821462],
        107000000: [59105279943, 0, 0, 58904149464, 118009429407],
        107000100: [1145982635, 0, 0, 1145061299, 2291043934],
        107000101: [0, 0, 0, 0, 0],
        107000102: [1145982635, 0, 0, 820518209, 1966500844],
        107000103: [0, 0, 0, 324543090, 324543090],
      },
    },
    {
      name: "Suma de balances indiv.",
      month: "Octubre",
      year: "2022",
      financialData: {
        100000000: [
          2203880600846, 1932296374194, 0, 462951679475, 4599128654515,
        ],
        105000000: [43318779162, 0, 0, 654465, 9191],
        105000100: [132123, 0, 0, 343434, 0],
        105000101: [0, 0, 12412124556, 0, 0],
        105000102: [0, 0, 124121246, 5151515, 0],
        105000103: [0, 0, 1241212456, 0, 0],
        105000200: [181818494949, 0, 12416, 0, 0],
        105000201: [0, 0, 1241212456, 0, 0],
        105000202: [0, 0, 1241212456, 0, 818181],
        105000209: [0, 818181, 1241212456, 0, 0],
        105000300: [0, 0, 1241212456, 0, 81959595],
        105000301: [0, 0, 0, 0, 0],
        105000302: [0, 0, 0, 0, 0],
        105000309: [0, 0, 0, 0, 0],
        105000400: [3035486746, 0, 0, 460949827, 3496436573],
        105000500: [0, 0, 0, 15808821462, 15808821462],
        107000000: [59105279943, 0, 0, 58904149464, 118009429407],
        107000100: [1145982635, 0, 0, 1145061299, 2291043934],
        107000101: [0, 0, 0, 0, 0],
        107000102: [1145982635, 0, 0, 820518209, 1966500844],
        107000103: [0, 0, 0, 324543090, 324543090],
      },
    },
    {
      name: "Ajustes de consolidación",
      month: "",
      year: "",
      ajuste: true,
      financialData: {
        100000000: ["", ""],
        105000000: ["", ""],
        105000100: ["", ""],
        105000101: ["", ""],
        105000102: ["", ""],
        105000103: ["", ""],
        105000200: [499935156, 106260301],
        105000201: ["", ""],
        105000202: ["", ""],
        105000209: ["", ""],
        105000300: ["", ""],
        105000301: ["", ""],
        105000302: ["", ""],
        105000309: ["", ""],
        105000400: ["", ""],
        105000500: ["", ""],
        107000000: ["", ""],
        107000100: ["", ""],
        107000101: ["", ""],
        107000102: ["", ""],
        107000103: ["", ""],
      },
    },
    {
      name: "Banco",
      month: "Octubre",
      year: "2022",
      financialData: {
        100000000: [
          2203880600846, 1932296374194, 0, 462951679475, 4599128654515,
        ],
        105000000: [43318779162, 0, 0, 654465, 9191],
        105000100: [132123, 0, 0, 343434, 0],
        105000101: [0, 0, 12412124556, 0, 0],
        105000102: [0, 0, 124121246, 5151515, 0],
        105000103: [0, 0, 1241212456, 0, 0],
        105000200: [181818494949, 0, 12416, 0, 0],
        105000201: [0, 0, 1241212456, 0, 0],
        105000202: [0, 0, 1241212456, 0, 818181],
        105000209: [0, 818181, 1241212456, 0, 0],
        105000300: [0, 0, 1241212456, 0, 81959595],
        105000301: [0, 0, 0, 0, 0],
        105000302: [0, 0, 0, 0, 0],
        105000309: [0, 0, 0, 0, 0],
        105000400: [3035486746, 0, 0, 460949827, 3496436573],
        105000500: [0, 0, 0, 15808821462, 15808821462],
        107000000: [59105279943, 0, 0, 58904149464, 118009429407],
        107000100: [1145982635, 0, 0, 1145061299, 2291043934],
        107000101: [0, 0, 0, 0, 0],
        107000102: [1145982635, 0, 0, 820518209, 1966500844],
        107000103: [0, 0, 0, 324543090, 324543090],
      },
    },
    {
      name: "AGF",
      month: "Octubre",
      year: "2022",
      financialData: {
        100000000: [
          2203880600846, 1932296374194, 0, 462951679475, 4599128654515,
        ],
        105000000: [43318779162, 0, 0, 654465, 9191],
        105000100: [132123, 0, 0, 343434, 0],
        105000101: [0, 0, 12412124556, 0, 0],
        105000102: [0, 0, 124121246, 5151515, 0],
        105000103: [0, 0, 1241212456, 0, 0],
        105000200: [181818494949, 0, 12416, 0, 0],
        105000201: [0, 0, 1241212456, 0, 0],
        105000202: [0, 0, 1241212456, 0, 818181],
        105000209: [0, 818181, 1241212456, 0, 0],
        105000300: [0, 0, 1241212456, 0, 81959595],
        105000301: [0, 0, 0, 0, 0],
        105000302: [0, 0, 0, 0, 0],
        105000309: [0, 0, 0, 0, 0],
        105000400: [3035486746, 0, 0, 460949827, 3496436573],
        105000500: [0, 0, 0, 15808821462, 15808821462],
        107000000: [59105279943, 0, 0, 58904149464, 118009429407],
        107000100: [1145982635, 0, 0, 1145061299, 2291043934],
        107000101: [0, 0, 0, 0, 0],
        107000102: [1145982635, 0, 0, 820518209, 1966500844],
        107000103: [0, 0, 0, 324543090, 324543090],
      },
    },
    {
      name: "CS",
      month: "Octubre",
      year: "2022",
      financialData: {
        100000000: [
          2203880600846, 1932296374194, 0, 462951679475, 4599128654515,
        ],
        105000000: [43318779162, 0, 0, 654465, 9191],
        105000100: [132123, 0, 0, 343434, 0],
        105000101: [0, 0, 12412124556, 0, 0],
        105000102: [0, 0, 124121246, 5151515, 0],
        105000103: [0, 0, 1241212456, 0, 0],
        105000200: [181818494949, 0, 12416, 0, 0],
        105000201: [0, 0, 1241212456, 0, 0],
        105000202: [0, 0, 1241212456, 0, 818181],
        105000209: [0, 818181, 1241212456, 0, 0],
        105000300: [0, 0, 1241212456, 0, 81959595],
        105000301: [0, 0, 0, 0, 0],
        105000302: [0, 0, 0, 0, 0],
        105000309: [0, 0, 0, 0, 0],
        105000400: [3035486746, 0, 0, 460949827, 3496436573],
        105000500: [0, 0, 0, 15808821462, 15808821462],
        107000000: [59105279943, 0, 0, 58904149464, 118009429407],
        107000100: [1145982635, 0, 0, 1145061299, 2291043934],
        107000101: [0, 0, 0, 0, 0],
        107000102: [1145982635, 0, 0, 820518209, 1966500844],
        107000103: [0, 0, 0, 324543090, 324543090],
      },
    },
    {
      name: "Suma de balances indiv.",
      month: "Octubre",
      year: "2022",
      financialData: {
        100000000: [
          2203880600846, 1932296374194, 0, 462951679475, 4599128654515,
        ],
        105000000: [43318779162, 0, 0, 654465, 9191],
        105000100: [132123, 0, 0, 343434, 0],
        105000101: [0, 0, 12412124556, 0, 0],
        105000102: [0, 0, 124121246, 5151515, 0],
        105000103: [0, 0, 1241212456, 0, 0],
        105000200: [181818494949, 0, 12416, 0, 0],
        105000201: [0, 0, 1241212456, 0, 0],
        105000202: [0, 0, 1241212456, 0, 818181],
        105000209: [0, 818181, 1241212456, 0, 0],
        105000300: [0, 0, 1241212456, 0, 81959595],
        105000301: [0, 0, 0, 0, 0],
        105000302: [0, 0, 0, 0, 0],
        105000309: [0, 0, 0, 0, 0],
        105000400: [3035486746, 0, 0, 460949827, 3496436573],
        105000500: [0, 0, 0, 15808821462, 15808821462],
        107000000: [59105279943, 0, 0, 58904149464, 118009429407],
        107000100: [1145982635, 0, 0, 1145061299, 2291043934],
        107000101: [0, 0, 0, 0, 0],
        107000102: [1145982635, 0, 0, 820518209, 1966500844],
        107000103: [0, 0, 0, 324543090, 324543090],
      },
    },
    {
      name: "Balance consolidado.",
      month: "",
      year: "",
      financialData: {
        100000000: [
          2203616589537, 1932296374194, 0, 462951679475, 4598864643206,
        ],
        105000000: [43668489000, 0, 0, 114206691970, 157875180970],
        105000100: [132123, 0, 0, 343434, 0],
        105000101: [0, 0, 12412124556, 0, 0],
        105000102: [0, 0, 124121246, 5151515, 0],
        105000103: [0, 0, 1241212456, 0, 0],
        105000200: [181818494949, 0, 12416, 0, 0],
        105000201: [0, 0, 1241212456, 0, 0],
        105000202: [0, 0, 1241212456, 0, 818181],
        105000209: [0, 818181, 1241212456, 0, 0],
        105000300: [0, 0, 1241212456, 0, 81959595],
        105000301: [0, 0, 0, 0, 0],
        105000302: [0, 0, 0, 0, 0],
        105000309: [0, 0, 0, 0, 0],
        105000400: [3035486746, 0, 0, 460949827, 3496436573],
        105000500: [0, 0, 0, 15808821462, 15808821462],
        107000000: [59105279943, 0, 0, 58904149464, 118009429407],
        107000100: [1145982635, 0, 0, 1145061299, 2291043934],
        107000101: [0, 0, 0, 0, 0],
        107000102: [1145982635, 0, 0, 820518209, 1966500844],
        107000103: [0, 0, 0, 324543090, 324543090],
      },
    },
  ];
  tableItems = [
    {
      rubro: "10000",
      linea: "00",
      item: "00",
      codigo: "100000000",
      descripcion: "TOTAL ACTIVOS",
    },
    {
      rubro: "10500",
      linea: "00",
      item: "00",
      codigo: "105000000",
      descripcion: "EFECTIVO Y DEPÓSITOS EN BANCOS",
    },
    {
      rubro: "10500",
      linea: "01",
      item: "00",
      codigo: "105000100",
      descripcion: "Efectivo",
    },
    {
      rubro: "10500",
      linea: "01",
      item: "01",
      codigoPadre: "105000100",
      codigo: "105000101",
      descripcion: "Efectivo en oficinas",
    },
    {
      rubro: "10500",
      linea: "01",
      item: "02",
      codigoPadre: "105000100",
      codigo: "105000102",
      descripcion: "Efectivo en empresas transportadoras de valores",
    },
    {
      rubro: "10500",
      linea: "01",
      item: "03",
      codigoPadre: "105000100",
      codigo: "105000103",
      descripcion: "Efectivo en custodia en otras entidades financieras",
    },
    {
      rubro: "10500",
      linea: "02",
      item: "03",
      codigo: "105000200",
      descripcion: "Depósitos en el Banco Central de Chile",
    },
    {
      rubro: "10500",
      linea: "02",
      item: "01",
      codigoPadre: "105000200",
      codigo: "105000201",
      descripcion: "Depósitos en cuenta corriente",
    },
    {
      rubro: "10500",
      linea: "02",
      item: "02",
      codigoPadre: "105000200",
      codigo: "105000202",
      descripcion: "Depósitos overnight",
    },
    {
      rubro: "10500",
      linea: "02",
      item: "09",
      codigoPadre: "105000200",
      codigo: "105000209",
      descripcion: "Otros depósitos disponibles",
    },
    {
      rubro: "10500",
      linea: "03",
      item: "00",
      codigo: "105000300",
      descripcion: "Depósitos en el Bancos Centrales del exterior",
    },
    {
      rubro: "10500",
      linea: "03",
      item: "01",
      codigoPadre: "105000300",
      codigo: "105000301",
      descripcion: "Depósitos en cuenta corriente",
    },
    {
      rubro: "10500",
      linea: "03",
      item: "02",
      codigoPadre: "105000300",
      codigo: "105000302",
      descripcion: "Depósitos overnight",
    },
    {
      rubro: "10500",
      linea: "03",
      item: "09",
      codigoPadre: "105000300",
      codigo: "105000309",
      descripcion: "Otros depósitos disponibles",
    },
    {
      rubro: "10500",
      linea: "04",
      item: "00",
      codigo: "105000400",
      descripcion: "Depósitos en bancos del país",
    },
    {
      rubro: "10500",
      linea: "05",
      item: "00",
      codigo: "105000500",
      descripcion: "Depósitos en bancos del exterior",
    },
    {
      rubro: "10700",
      linea: "00",
      item: "00",
      codigo: "107000000",
      descripcion: "OPERACIONES CON LA LIQUIDACIÓN EN CURSO",
    },
    {
      rubro: "10700",
      linea: "01",
      item: "00",
      codigo: "107000100",
      descripcion: "Documentos a cargo de otros bancos (canje)",
    },
    {
      rubro: "10700",
      linea: "03",
      item: "01",
      codigoPadre: "107000100",
      codigo: "107000101",
      descripcion: "Canje no deducible",
    },
    {
      rubro: "10700",
      linea: "03",
      item: "02",
      codigoPadre: "107000100",
      codigo: "107000102",
      descripcion: "Canje de la plaza",
    },
    {
      rubro: "10700",
      linea: "03",
      item: "09",
      codigoPadre: "107000100",
      codigo: "107000103",
      descripcion: "Canje de otras plazas",
    },
  ];

  const spaceTab = "\u00A0\u00A0\u00A0\u00A0";
  const itemsCodigo = [];
  tableItems.map((item, index) => {
    itemsCodigo.push(item.codigo);
  });
  return (
    <>
      <div className="row pt-4">
        <div className="col-5">
          <h5
            style={{
              visibility: "hidden",
            }}
          >
            -
          </h5>
          <Table striped hover className="border-shadow">
            <thead>
              <tr>
                <th>Rubro</th>
                <th>Línea</th>
                <th>ítem</th>
                <th>Código</th>
                <th>Descripción del código</th>
              </tr>
            </thead>
            <tbody>
              {tableItems.map((item, index) => {
                const style =
                  index == 0 ? "text-turquoise" : index == 1 ? "text-blue" : "";
                return (
                  <tr key={index}>
                    <td className={style}>{item.rubro}</td>
                    <td className={style}>{item.linea}</td>
                    <td className={style}>{item.item}</td>
                    <td className={style}>{item.codigo}</td>
                    <td
                      className={
                        !item.codigoPadre ? "fw-bold " + style : "" + style
                      }
                    >
                      {item.codigoPadre
                        ? spaceTab + item.descripcion
                        : item.descripcion}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className="col-7">
          <div className="horizontal-scroll">
            {bussines.map((itemBussines, index) => {
              if (itemBussines.ajuste) {
                return (
                  <>
                    <div className="display-inline" key={index}>
                      <h5 className="text-center">{itemBussines.name}</h5>
                      <div className="col-11 border-shadow">
                        <Table striped hover className="ps-1 pe-1">
                          <thead className="border-top-gray ">
                            <tr>
                              <th>AGF</th>
                              <th>CS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {itemsCodigo.map((financeItem, index) => {
                              return (
                                <tr key={financeItem}>
                                  {itemBussines.financialData[
                                    "" + financeItem
                                  ].map((valueItem, index) => {
                                    if (valueItem != "") {
                                      return (
                                        <td
                                          td
                                          className="border-bottom-cyan"
                                          key={financeItem + index}
                                        >
                                          {valueItem}
                                        </td>
                                      );
                                    }
                                    return (
                                      <td key={financeItem + index}>
                                        <br />
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </>
                );
              }
              return (
                <>
                  <div className="display-inline" key={index}>
                    <h5 className="text-center text-red">
                      {itemBussines.name} ({itemBussines.month}{" "}
                      {itemBussines.year})
                    </h5>
                    <div className="col-11 border-shadow">
                      <Table striped hover className="ps-1 pe-1">
                        <thead className="border-top-red ">
                          <tr>
                            <th>MChnR</th>
                            <th>MR(IPC)</th>
                            <th>MR(TC)</th>
                            <th>ME</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {itemsCodigo.map((financeItem, index) => {
                            const style =
                              index == 0
                                ? "text-turquoise"
                                : index == 1
                                ? "text-blue"
                                : "";
                            return (
                              <tr key={financeItem}>
                                {itemBussines.financialData[
                                  "" + financeItem
                                ].map((valueItem, index) => {
                                  return (
                                    <td
                                      className={style}
                                      key={financeItem + index}
                                    >
                                      {valueItem}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      <div className="row justify-content-end mt-5 pt-2 pb-2 bg-blue">
        <div className="col-auto">
          <p className="text-white fw-semibold pt-2">
            ¿Desea validar sumatoria?
          </p>
        </div>
        <div className="col-auto">
          <button
            className="btn btn-danger"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasValidarAsientoConsolidacion"
            aria-controls="offcanvasValidarAsientoConsolidacion"
          >
            Validar
          </button>
        </div>
        <div className="col-auto">
          <Link to="/consolidacion" className="btn btn-primary">Rechazar</Link>
        </div>
      </div>
      <OffcanvasValidarAsientoConsolidacionComponent/>
    </>
  );
};
