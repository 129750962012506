import React from "react";

export const TableReportesMR1 = () => {
  const reportes = [
    "0009MR1202210",
    "41100000000144657698119+",
    "41110000000116019165350+",
    "41115000000000000000000+",
    "41115010000000000000000+",
    "41115010100000000000000+",
    "41115010200000000000000+",
    "41115010300000000000000+",
    "41115020000000000000000+",
    "41115020100000000000000+",
    "41115020200000000000000+",
    "41115020300000000000000+",
    "41115030000000000000000+",
    "41115030100000000000000+",
    "41115030200000000000000+",
    "41115040000000000000000+",
    "41115040100000000000000+",
    "41115040200000000000000+",
    "41120000000000000000000+",
    "41120010000000000000000+",
    "41120010100000000000000+",
    "41120010200000000000000+",
    "41120010900000000000000+",
    "41120020000000000000000+",
    "41120020100000000000000+",
    "41120020200000000000000+",
    "41120020900000000000000+",
    "41120030000000000000000+",
    "41120030100000000000000+",
    "41120030200000000000000+",
    "41120030300000000000000+",
    "41120030400000000000000+",
    "41120030900000000000000+",
  ];
  return (
    <div className="table-reportes">
    <div className="table-reportes-id">
      {reportes.map((reporte) => ( <p> {reporte}</p>))}
    </div>
    <div className="table-reportes-numero">
      <span>Caracteres</span>
      {reportes.map((reporte) => ( <span>{reporte.length}</span>))}
    </div>
  </div>
  );
};
