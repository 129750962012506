import { useFormik } from "formik";
import React, { useEffect, useId, useState } from "react";
import { Link } from "react-router-dom";
import { AsientosConsolidacionComponentTable } from "./AsientosConsolidacionComponentTable";

export const AsientosConsolidacionComponent = (props) => {
  const [tableId, setTableId] = useState(useId());
  const [agregarTabla, setAgregarTabla] = useState(false);
  const [tablasAsientosConsolidacion, setTablasAsientosConsolidacion] =
    useState([
      {
        filas: [
          {
            empresa: "BANCO",
            cuenta: "2100109100100000",
            ifrs: "241000103",
            descripcion: "CTA CTE de otras personas jurídicas",
            debe: "432,303,660",
            ajuste: "432,303,660",
            glosa: "Eliminar CTA. CTE.",
            rowId: useId(),
          },
          {
            empresa: "AGF",
            cuenta: "110102017",
            ifrs: "105000400",
            descripcion: "INTERNACIONAL $",
            haber: "432,303,660",
            ajuste: "432,303,660",
            glosa: "Eliminar CTA. CTE.",
            rowId: useId(),
          },
        ],
        tableId: useId(),
      },
      {
        filas: [
          {
            empresa: "BANCO",
            cuenta: "1800990018500000",
            ifrs: "190002900",
            descripcion: "Otros activos",
            haber: "40,722,755",
            ajuste: "40,722,755",
            glosa: "Eliminar cuentas por cobrar AGF",
            rowId: useId(),
          },
          {
            empresa: "AGF",
            cuenta: "210850252",
            ifrs: "290000390",
            debe: "40,722,755",
            descripcion: "Leyes sociales por pagar",
            glosa: "Eliminar cuentas por ",
            rowId: useId(),
          },
          {
            empresa: "AGF",
            cuenta: "2101010233",
            ifrs: "290000390",
            descripcion: "Cuentas por pagar Banco Internacional",
            debe: "40,722,755",
            ajuste: "40,722,755",
            glosa: "cobrar AGF",
            rowId: useId(),
          },
        ],
        tableId: useId(),
      },
    ]);

  const handleAddAsiento = (e) => {
    setAgregarTabla(true);
  };
  const handleSaveAsiento = (e) => {
    const newTable = {
      filas: [
        {
          empresa: formik.values.empresa,
          cuenta: formik.values.cuenta,
          ifrs: formik.values.ifrs,
          descripcion: formik.values.descripcion,
          debe: formik.values.debe,
          haber: formik.values.haber,
          ajuste: formik.values.ajuste,
          glosa: formik.values.glosa,
          rowId: tableId,
        },
      ],
      tableId: tableId,
    };
    setTablasAsientosConsolidacion([...tablasAsientosConsolidacion, newTable]);
    setAgregarTabla(false)
  };
  const initialValues = {
    empresa: "1",
    cuenta: "1",
    ifrs: "1",
    descripcion: "1",
    debe: "1",
    haber: "1",
    ajuste: "1",
    glosa: "1",
  };
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <>
      <div className="row mt-5">
        {tablasAsientosConsolidacion.map((tablaAsientoContable, index) => (
          <AsientosConsolidacionComponentTable
            tablaAsientoContable={tablaAsientoContable}
            key={index}
          />
        ))}
      </div>
      {agregarTabla && (
        <div className="col-12 mb-5 table-asientos-wrapper">
          <table className="bi-table table-asientos">
            <thead>
              <tr>
                <th>Empresa</th>
                <th>Cuenta</th>
                <th>IFRS</th>
                <th>Descripcion</th>
                <th>Debe</th>
                <th>Haber</th>
                <th>Ajuste</th>
                <th>Glosa</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p>
                    <input
                      type="text"
                      className="form-control"
                      id="empresa"
                      onChange={formik.handleChange}
                      value={formik.values.empresa}
                    />
                  </p>
                </td>
                <td>
                  <p>
                    <input
                      type="text"
                      className="form-control"
                      id="cuenta"
                      onChange={formik.handleChange}
                      value={formik.values.cuenta}
                    />
                  </p>
                </td>
                <td>
                  <p>
                    <input
                      type="text"
                      className="form-control"
                      id="ifrs"
                      onChange={formik.handleChange}
                      value={formik.values.ifrs}
                    />
                  </p>
                </td>
                <td>
                  <p>
                    <input
                      type="text"
                      className="form-control"
                      id="descripcion"
                      onChange={formik.handleChange}
                      value={formik.values.descripcion}
                    />
                  </p>
                </td>
                <td>
                  <p>
                    <input
                      type="text"
                      className="form-control"
                      id="debe"
                      onChange={formik.handleChange}
                      value={formik.values.debe}
                    />
                  </p>
                </td>
                <td>
                  <p>
                    <input
                      type="text"
                      className="form-control"
                      id="haber"
                      onChange={formik.handleChange}
                      value={formik.values.haber}
                    />
                  </p>
                </td>
                <td>
                  <p>
                    <input
                      type="text"
                      className="form-control"
                      id="ajuste"
                      onChange={formik.handleChange}
                      value={formik.values.ajuste}
                    />
                  </p>
                </td>
                <td>
                  <p>
                    <input
                      type="text"
                      className="form-control"
                      id="glosa"
                      onChange={formik.handleChange}
                      value={formik.values.glosa}
                    />
                  </p>
                </td>
                <td>
                  <span
                    className="bi-table-accion bi-table-accion-edit"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMantenedorEditarIFRS"
                    aria-controls="offcanvasRight"
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="15"
                        cy="15"
                        r="14"
                        fill="white"
                        stroke="#001A72"
                        strokeWidth="2"
                      />
                      <path
                        d="M22.5 11.43C22.5006 11.3313 22.4816 11.2335 22.4443 11.1421C22.407 11.0507 22.352 10.9676 22.2825 10.8975L19.1025 7.71751C19.0324 7.648 18.9493 7.59301 18.8579 7.55568C18.7665 7.51836 18.6687 7.49944 18.57 7.50001C18.4713 7.49944 18.3734 7.51836 18.2821 7.55568C18.1907 7.59301 18.1076 7.648 18.0375 7.71751L15.915 9.84001L7.71751 18.0375C7.648 18.1076 7.59301 18.1907 7.55568 18.2821C7.51836 18.3734 7.49944 18.4713 7.50001 18.57V21.75C7.50001 21.9489 7.57903 22.1397 7.71968 22.2803C7.86033 22.421 8.0511 22.5 8.25001 22.5H11.43C11.535 22.5057 11.6399 22.4893 11.7381 22.4518C11.8363 22.4143 11.9255 22.3567 12 22.2825L20.1525 14.085L22.2825 12C22.3509 11.9273 22.4067 11.8437 22.4475 11.7525C22.4547 11.6927 22.4547 11.6323 22.4475 11.5725C22.451 11.5376 22.451 11.5024 22.4475 11.4675L22.5 11.43ZM11.1225 21H9.00001V18.8775L16.4475 11.43L18.57 13.5525L11.1225 21ZM19.6275 12.495L17.505 10.3725L18.57 9.31501L20.685 11.43L19.6275 12.495Z"
                        fill="#001A72"
                      />
                    </svg>
                  </span>
                  <span
                    className="bi-table-accion"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMantenedorBorrarIFRS"
                    aria-controls="offcanvasRight"
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="15"
                        cy="15"
                        r="14"
                        fill="white"
                        stroke="#001A72"
                        strokeWidth="2"
                      />
                      <path
                        d="M13.5 19.5C13.6989 19.5 13.8897 19.421 14.0303 19.2803C14.171 19.1397 14.25 18.9489 14.25 18.75V14.25C14.25 14.0511 14.171 13.8603 14.0303 13.7197C13.8897 13.579 13.6989 13.5 13.5 13.5C13.3011 13.5 13.1103 13.579 12.9697 13.7197C12.829 13.8603 12.75 14.0511 12.75 14.25V18.75C12.75 18.9489 12.829 19.1397 12.9697 19.2803C13.1103 19.421 13.3011 19.5 13.5 19.5ZM21 10.5H18V9.75C18 9.15326 17.7629 8.58097 17.341 8.15901C16.919 7.73705 16.3467 7.5 15.75 7.5H14.25C13.6533 7.5 13.081 7.73705 12.659 8.15901C12.2371 8.58097 12 9.15326 12 9.75V10.5H9C8.80109 10.5 8.61032 10.579 8.46967 10.7197C8.32902 10.8603 8.25 11.0511 8.25 11.25C8.25 11.4489 8.32902 11.6397 8.46967 11.7803C8.61032 11.921 8.80109 12 9 12H9.75V20.25C9.75 20.8467 9.98705 21.419 10.409 21.841C10.831 22.2629 11.4033 22.5 12 22.5H18C18.5967 22.5 19.169 22.2629 19.591 21.841C20.0129 21.419 20.25 20.8467 20.25 20.25V12H21C21.1989 12 21.3897 11.921 21.5303 11.7803C21.671 11.6397 21.75 11.4489 21.75 11.25C21.75 11.0511 21.671 10.8603 21.5303 10.7197C21.3897 10.579 21.1989 10.5 21 10.5ZM13.5 9.75C13.5 9.55109 13.579 9.36032 13.7197 9.21967C13.8603 9.07902 14.0511 9 14.25 9H15.75C15.9489 9 16.1397 9.07902 16.2803 9.21967C16.421 9.36032 16.5 9.55109 16.5 9.75V10.5H13.5V9.75ZM18.75 20.25C18.75 20.4489 18.671 20.6397 18.5303 20.7803C18.3897 20.921 18.1989 21 18 21H12C11.8011 21 11.6103 20.921 11.4697 20.7803C11.329 20.6397 11.25 20.4489 11.25 20.25V12H18.75V20.25ZM16.5 19.5C16.6989 19.5 16.8897 19.421 17.0303 19.2803C17.171 19.1397 17.25 18.9489 17.25 18.75V14.25C17.25 14.0511 17.171 13.8603 17.0303 13.7197C16.8897 13.579 16.6989 13.5 16.5 13.5C16.3011 13.5 16.1103 13.579 15.9697 13.7197C15.829 13.8603 15.75 14.0511 15.75 14.25V18.75C15.75 18.9489 15.829 19.1397 15.9697 19.2803C16.1103 19.421 16.3011 19.5 16.5 19.5Z"
                        fill="#001A72"
                      />
                    </svg>
                  </span>
                </td>
              </tr>
              <tr className="table-asientos-total">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="table-asientos-totales">
                  <p>-</p>
                </td>
                <td className="table-asientos-totales">
                  <p>-</p>
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <button type="button" className="table-asientos-add-row">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="#6E7682"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18ZM14 9H11V6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5C9.73479 5 9.48043 5.10536 9.2929 5.29289C9.10536 5.48043 9 5.73478 9 6V9H6C5.73479 9 5.48043 9.10536 5.2929 9.29289C5.10536 9.48043 5 9.73478 5 10C5 10.2652 5.10536 10.5196 5.2929 10.7071C5.48043 10.8946 5.73479 11 6 11H9V14C9 14.2652 9.10536 14.5196 9.2929 14.7071C9.48043 14.8946 9.73479 15 10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14V11H14C14.2652 11 14.5196 10.8946 14.7071 10.7071C14.8946 10.5196 15 10.2652 15 10C15 9.73478 14.8946 9.48043 14.7071 9.29289C14.5196 9.10536 14.2652 9 14 9Z"
                fill="#6E7682"
              />
            </svg>
          </button>
        </div>
      )}
      <div className="d-flex justify-content-end" id="asientos-control-btns">
        <Link to="/consolidacion/asientos-de-consolidacion/vista-previa" className="btn">
          Ver ajustes en balance
        </Link>
        <a
          className="ms-3 btn btn-outline-primary"
          onClick={handleAddAsiento}
          id="agregarAsiento"
        >
          Añadir asiento
        </a>
        {agregarTabla && (
          <a
            className="ms-3 btn btn-primary"
            onClick={handleSaveAsiento}
            id="guardarAsiento"
          >
            Guardar asiento
          </a>
        )}
      </div>
    </>
  );
};
