/* eslint-disable no-unused-expressions */
import { createContext, useCallback, useContext, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { login, logout } from '../../features/user/userSlice'
export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const user = useSelector((state) => state.user.value);
    const dispatch = useDispatch()
    console.log("user",user);
    
    const loginUser = useCallback(() => {
        dispatch(login(
            {
                name: "BC - VR",
                email: "",
                photo: "",
                isLogged: true
            }
        ))
    }, [])

    const logoutUser = useCallback(() => {
        dispatch(logout())
    }, [])

    const value = useMemo(() =>( {loginUser, logoutUser, user}), [login, logout, user])
    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuthContext = () => {
    return useContext(AuthContext)
}

AuthContextProvider.propTypes = {
    children: PropTypes.object
}

