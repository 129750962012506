import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { LoginPageComponent } from './pages/Login/LoginPageComponent'
import { NotFoundPageComponent } from './pages/Notfound/NotFoundPageComponent'
import { AuthContextProvider } from './Auth/AuthContext/AuthContext'
import { PublicRoutes } from './routes/PublicRoutes'
import { PrivateRoutes } from './routes/PrivateRoutes'
import { UsuariosPageComponent } from './pages/Usuarios/UsuariosPageComponent'
import "./styles/main.css"
import { NavbarComponent } from './components/navbar/NavbarComponent'
import { MantenedorIFRS } from './pages/Mantenedores/MantenedorIFRS'
import { MantenedorRelacionEntreCuentas } from './pages/Mantenedores/MantenedorRelacionEntreCuentas'
import { ConsolidacionPageComponent } from './pages/Consolidacion/ConsolidacionPageComponent'
import { ReportesPageComponent } from './pages/Reportes/ReportesPageComponent'
import { AsientosConsolidacionVistaPreviaComponent } from './pages/Consolidacion/Asientos/AsientosConsolidacionVistaPreviaComponent'
function App() {
  return (
    <AuthContextProvider>
      <BrowserRouter>
        <NavbarComponent/>
        <Routes>
          <Route path='/' element={ <PublicRoutes/> }>
            <Route index element={<Navigate to='login' />} />
            <Route path="login" element={<LoginPageComponent />} />
            <Route path="*" element={<NotFoundPageComponent />} />
          </Route>
          
          <Route path='/' element={<PrivateRoutes />}>
            <Route index path="usuarios" element={ <UsuariosPageComponent/> } />
            <Route path="/mantenedores">
              <Route path='ifrs' element={ <MantenedorIFRS/> } />
              <Route path="relaciones-entre-cuentas" element={ <MantenedorRelacionEntreCuentas/> } />
            </Route>
            <Route path='consolidacion'>
              <Route path="" element={<ConsolidacionPageComponent />}  />
              <Route path='asientos-de-consolidacion'>
                <Route path="vista-previa" element={ <AsientosConsolidacionVistaPreviaComponent /> } />
              </Route>
            </Route>
            <Route path="reportes" element={ <ReportesPageComponent /> } />
            <Route path="*" element={<NotFoundPageComponent />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthContextProvider>
  )
}

export default App
