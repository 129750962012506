import React from 'react'
import { Link } from 'react-router-dom'
import './steptabnavstyle.css'
export const StepTabnavConsolidadosComponent = (props) => {
    const { stages } = props
    return (
        <ul className="nav">
            {
                stages.map((stage) => {
                    return (
                        <li className="nav-item" key={stage.id}>
                            <Link className={`nav-link fw-semibold ${stage.id==1?'active':''}`} to={`#${stage.idHtml}`} data-bs-toggle="tab">{stage.name}</Link>
                        </li>
                    )
                })
            }
        </ul>
    )
}
