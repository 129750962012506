import React from "react";
import { TableUsuariosRow } from "./TableUsuariosRow";

export const TableUsuarios = (props) => {
  const {usuarios} = props
  return (
    <table className="bi-table">
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Apellidos</th>
          <th>Correo</th>
          <th>Cargo</th>
          <th>Usuarios</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {usuarios.map((usuario, index) => (
          <TableUsuariosRow usuario={usuario} key={index} />
        ))}
      </tbody>
    </table>
  );
};
