import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom"

export const PrivateRoutes = () => {
  const user = useSelector((state) => state.user.value);
  if (user.isLogged) {
    return <Outlet />
  }
  return (
    <Navigate to='login' />
  )
}
