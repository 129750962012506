import React from 'react'

export const DeniedBodyValidacionSumaBalanceComponent = (props) => {
    return (
        <div className="offcanvas-body denied">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="row">
                            <div className="col-12 text-center pt-5 pb-5" >
                                <span>
                                    <svg width="59" height="60" viewBox="0 0 59 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M55.4595 0H2.91892C2.14477 0 1.40233 0.31607 0.854931 0.87868C0.307528 1.44129 0 2.20435 0 3V57C0 57.7957 0.307528 58.5587 0.854931 59.1213C1.40233 59.6839 2.14477 60 2.91892 60H55.4595C56.2336 60 56.976 59.6839 57.5234 59.1213C58.0709 58.5587 58.3784 57.7957 58.3784 57V3C58.3784 2.20435 58.0709 1.44129 57.5234 0.87868C56.976 0.31607 56.2336 0 55.4595 0ZM52.5405 54H5.83784V6H52.5405V54Z" fill="#D90D39" />
                                        <path d="M38.7822 18.0558C39.1275 18.1996 39.4409 18.4104 39.7043 18.676C39.9699 18.9395 40.1807 19.2529 40.3246 19.5982C40.4685 19.9435 40.5425 20.3139 40.5425 20.688C40.5425 21.0621 40.4685 21.4325 40.3246 21.7778C40.1807 22.1231 39.9699 22.4365 39.7043 22.6999L33.1868 29.1892L39.7043 35.6784C39.9699 35.9419 40.1807 36.2553 40.3246 36.6006C40.4685 36.9459 40.5425 37.3163 40.5425 37.6904C40.5425 38.0645 40.4685 38.4348 40.3246 38.7802C40.1807 39.1255 39.9699 39.4389 39.7043 39.7023C39.4409 39.9679 39.1275 40.1787 38.7822 40.3226C38.4369 40.4665 38.0665 40.5405 37.6924 40.5405C37.3183 40.5405 36.9479 40.4665 36.6026 40.3226C36.2573 40.1787 35.9439 39.9679 35.6804 39.7023L29.1912 33.1847L22.702 39.7023C22.4385 39.9679 22.1251 40.1787 21.7798 40.3226C21.4345 40.4665 21.0641 40.5405 20.69 40.5405C20.3159 40.5405 19.9455 40.4665 19.6002 40.3226C19.2549 40.1787 18.9415 39.9679 18.6781 39.7023C18.4125 39.4389 18.2016 39.1255 18.0578 38.7802C17.9139 38.4348 17.8398 38.0645 17.8398 37.6904C17.8398 37.3163 17.9139 36.9459 18.0578 36.6006C18.2016 36.2553 18.4125 35.9419 18.6781 35.6784L25.1956 29.1892L18.6781 22.6999C18.1445 22.1663 17.8447 21.4426 17.8447 20.688C17.8447 19.9334 18.1445 19.2096 18.6781 18.676C19.2117 18.1424 19.9354 17.8427 20.69 17.8427C21.4446 17.8427 22.1684 18.1424 22.702 18.676L29.1912 25.1936L35.6804 18.676C35.9439 18.4104 36.2573 18.1996 36.6026 18.0558C36.9479 17.9119 37.3183 17.8378 37.6924 17.8378C38.0665 17.8378 38.4369 17.9119 38.7822 18.0558Z" fill="#D90D39" />
                                    </svg>

                                </span>
                                <h5 className='fw-semibold text-red pt-4'>Sumatoria incorrecta</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center pt-3">
                                <h5 className='fw-bold'>$14,000,000</h5>
                                <p className='text-muted'>Activo</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center pt-3">
                                <h5 className='fw-bold'>$4,000,000</h5>
                                <p className='text-muted'>Pasivo</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center pt-3">
                                <h5 className='fw-bold'>$10,000,000</h5>
                                <p className='text-muted'>Patrimonio</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center pt-3">
                                <h5 className='fw-bold text-red'>100</h5>
                                <p className='text-muted'>Diferencia</p>
                            </div>
                        </div>
                        <div className="row mt-5 mb-5">
                            <div className="col-12 pb-2 text-center">
                                <button className='btn btn-primary' data-bs-dismiss="offcanvas" aria-label="Close">Aceptar</button>
                            </div>
                            <div className="col-12 pt-2 text-center">
                                <a className='text-button btn' data-bs-dismiss="offcanvas" aria-label="Close">Cancelar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
