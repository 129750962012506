import React from "react";

export const TableUsuariosRow = (props) => {
  const { usuario} = props;
  const { nombre, apellidos, correo, cargo, usuarios } = usuario;
  return (
    <tr>
      <td>
        <p>{nombre}</p>
      </td>
      <td>
        <p>{apellidos}</p>
      </td>
      <td>
        <p>{correo}</p>
      </td>
      <td>
        <p>{cargo}</p>
      </td>
      <td>
        <p>{usuarios}</p>
      </td>
      <td>
        <span
          className="bi-table-accion"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasEditarUsuarios"
          aria-controls="offcanvasRight"
        >
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="15"
              cy="15"
              r="14"
              fill="white"
              stroke="#001A72"
              strokeWidth="2"
            />
            <path
              d="M22.5 11.43C22.5006 11.3313 22.4816 11.2335 22.4443 11.1421C22.407 11.0507 22.352 10.9676 22.2825 10.8975L19.1025 7.71751C19.0324 7.648 18.9493 7.59301 18.8579 7.55568C18.7665 7.51836 18.6687 7.49944 18.57 7.50001C18.4713 7.49944 18.3734 7.51836 18.2821 7.55568C18.1907 7.59301 18.1076 7.648 18.0375 7.71751L15.915 9.84001L7.71751 18.0375C7.648 18.1076 7.59301 18.1907 7.55568 18.2821C7.51836 18.3734 7.49944 18.4713 7.50001 18.57V21.75C7.50001 21.9489 7.57903 22.1397 7.71968 22.2803C7.86033 22.421 8.0511 22.5 8.25001 22.5H11.43C11.535 22.5057 11.6399 22.4893 11.7381 22.4518C11.8363 22.4143 11.9255 22.3567 12 22.2825L20.1525 14.085L22.2825 12C22.3509 11.9273 22.4067 11.8437 22.4475 11.7525C22.4547 11.6927 22.4547 11.6323 22.4475 11.5725C22.451 11.5376 22.451 11.5024 22.4475 11.4675L22.5 11.43ZM11.1225 21H9.00001V18.8775L16.4475 11.43L18.57 13.5525L11.1225 21ZM19.6275 12.495L17.505 10.3725L18.57 9.31501L20.685 11.43L19.6275 12.495Z"
              fill="#001A72"
            />
          </svg>
        </span>
      </td>
    </tr>
  );
};
