import { useFormik } from "formik";
import React, { useState } from "react";

export const AsientosConsolidacionComponentTableRow = (props) => {
  const [editar, setEditar] = useState(false);
  const { asientoContable } = props;
  const {
    empresa,
    cuenta,
    ifrs,
    descripcion,
    debe = "-",
    haber = "-",
    ajuste = "-",
    glosa,
    rowId,
  } = asientoContable;
  const handleEditRow = () => {
    setEditar(true);
  };
  const handleSaveRow = () => {
    setEditar(false);
  };
  const initialValues = {
    empresa,
    cuenta,
    ifrs,
    descripcion,
    debe,
    haber,
    ajuste,
    glosa,
  };
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <tr id={rowId}>
      <td>
        <p>
          {editar ? (
            <input
              type="text"
              className="form-control"
              id="empresa"
              onChange={formik.handleChange}
              value={formik.values.empresa}
            />
          ) : (
            empresa
          )}
        </p>
      </td>
      <td>
        <p>
          {editar ? (
            <input
              type="text"
              className="form-control"
              id="cuenta"
              onChange={formik.handleChange}
              value={formik.values.cuenta}
            />
          ) : (
            cuenta
          )}
        </p>
      </td>
      <td>
        <p>
          {editar ? (
            <input
              type="text"
              className="form-control"
              id="ifrs"
              onChange={formik.handleChange}
              value={formik.values.ifrs}
            />
          ) : (
            ifrs
          )}
        </p>
      </td>
      <td>
        <p>
          {editar ? (
            <input
              type="text"
              className="form-control"
              id="descripcion"
              onChange={formik.handleChange}
              value={formik.values.descripcion}
            />
          ) : (
            descripcion
          )}
        </p>
      </td>
      <td>
        <p>
          {editar ? (
            <input
              type="text"
              className="form-control"
              id="debe"
              onChange={formik.handleChange}
              value={formik.values.debe}
            />
          ) : (
            debe
          )}
        </p>
      </td>
      <td>
        <p>
          {editar ? (
            <input
              type="text"
              className="form-control"
              id="haber"
              onChange={formik.handleChange}
              value={formik.values.haber}
            />
          ) : (
            haber
          )}
        </p>
      </td>
      <td>
        <p>
          {editar ? (
            <input
              type="text"
              className="form-control"
              id="ajuste"
              onChange={formik.handleChange}
              value={formik.values.ajuste}
            />
          ) : (
            ajuste
          )}
        </p>
      </td>
      <td>
        <p>
          {editar ? (
            <input
              type="text"
              className="form-control"
              id="glosa"
              onChange={formik.handleChange}
              value={formik.values.glosa}
            />
          ) : (
            glosa
          )}
        </p>
      </td>
      <td>
        {!editar && (
          <>
            <span
              className="bi-table-accion bi-table-accion-edit"
              onClick={handleEditRow}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="15"
                  cy="15"
                  r="14"
                  fill="white"
                  stroke="#001A72"
                  strokeWidth="2"
                />
                <path
                  d="M22.5 11.43C22.5006 11.3313 22.4816 11.2335 22.4443 11.1421C22.407 11.0507 22.352 10.9676 22.2825 10.8975L19.1025 7.71751C19.0324 7.648 18.9493 7.59301 18.8579 7.55568C18.7665 7.51836 18.6687 7.49944 18.57 7.50001C18.4713 7.49944 18.3734 7.51836 18.2821 7.55568C18.1907 7.59301 18.1076 7.648 18.0375 7.71751L15.915 9.84001L7.71751 18.0375C7.648 18.1076 7.59301 18.1907 7.55568 18.2821C7.51836 18.3734 7.49944 18.4713 7.50001 18.57V21.75C7.50001 21.9489 7.57903 22.1397 7.71968 22.2803C7.86033 22.421 8.0511 22.5 8.25001 22.5H11.43C11.535 22.5057 11.6399 22.4893 11.7381 22.4518C11.8363 22.4143 11.9255 22.3567 12 22.2825L20.1525 14.085L22.2825 12C22.3509 11.9273 22.4067 11.8437 22.4475 11.7525C22.4547 11.6927 22.4547 11.6323 22.4475 11.5725C22.451 11.5376 22.451 11.5024 22.4475 11.4675L22.5 11.43ZM11.1225 21H9.00001V18.8775L16.4475 11.43L18.57 13.5525L11.1225 21ZM19.6275 12.495L17.505 10.3725L18.57 9.31501L20.685 11.43L19.6275 12.495Z"
                  fill="#001A72"
                />
              </svg>
            </span>
            <span className="bi-table-accion">
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="15"
                  cy="15"
                  r="14"
                  fill="white"
                  stroke="#001A72"
                  strokeWidth="2"
                />
                <path
                  d="M13.5 19.5C13.6989 19.5 13.8897 19.421 14.0303 19.2803C14.171 19.1397 14.25 18.9489 14.25 18.75V14.25C14.25 14.0511 14.171 13.8603 14.0303 13.7197C13.8897 13.579 13.6989 13.5 13.5 13.5C13.3011 13.5 13.1103 13.579 12.9697 13.7197C12.829 13.8603 12.75 14.0511 12.75 14.25V18.75C12.75 18.9489 12.829 19.1397 12.9697 19.2803C13.1103 19.421 13.3011 19.5 13.5 19.5ZM21 10.5H18V9.75C18 9.15326 17.7629 8.58097 17.341 8.15901C16.919 7.73705 16.3467 7.5 15.75 7.5H14.25C13.6533 7.5 13.081 7.73705 12.659 8.15901C12.2371 8.58097 12 9.15326 12 9.75V10.5H9C8.80109 10.5 8.61032 10.579 8.46967 10.7197C8.32902 10.8603 8.25 11.0511 8.25 11.25C8.25 11.4489 8.32902 11.6397 8.46967 11.7803C8.61032 11.921 8.80109 12 9 12H9.75V20.25C9.75 20.8467 9.98705 21.419 10.409 21.841C10.831 22.2629 11.4033 22.5 12 22.5H18C18.5967 22.5 19.169 22.2629 19.591 21.841C20.0129 21.419 20.25 20.8467 20.25 20.25V12H21C21.1989 12 21.3897 11.921 21.5303 11.7803C21.671 11.6397 21.75 11.4489 21.75 11.25C21.75 11.0511 21.671 10.8603 21.5303 10.7197C21.3897 10.579 21.1989 10.5 21 10.5ZM13.5 9.75C13.5 9.55109 13.579 9.36032 13.7197 9.21967C13.8603 9.07902 14.0511 9 14.25 9H15.75C15.9489 9 16.1397 9.07902 16.2803 9.21967C16.421 9.36032 16.5 9.55109 16.5 9.75V10.5H13.5V9.75ZM18.75 20.25C18.75 20.4489 18.671 20.6397 18.5303 20.7803C18.3897 20.921 18.1989 21 18 21H12C11.8011 21 11.6103 20.921 11.4697 20.7803C11.329 20.6397 11.25 20.4489 11.25 20.25V12H18.75V20.25ZM16.5 19.5C16.6989 19.5 16.8897 19.421 17.0303 19.2803C17.171 19.1397 17.25 18.9489 17.25 18.75V14.25C17.25 14.0511 17.171 13.8603 17.0303 13.7197C16.8897 13.579 16.6989 13.5 16.5 13.5C16.3011 13.5 16.1103 13.579 15.9697 13.7197C15.829 13.8603 15.75 14.0511 15.75 14.25V18.75C15.75 18.9489 15.829 19.1397 15.9697 19.2803C16.1103 19.421 16.3011 19.5 16.5 19.5Z"
                  fill="#001A72"
                />
              </svg>
            </span>
          </>
        )}
        {editar && (
          <span className="bi-table-accion" onClick={handleSaveRow}>
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="15"
                cy="15"
                r="14"
                stroke="#001A72"
                stroke-width="2"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.3268 7.00015L8.09868 7L8.09873 7.00006C7.49275 7.00006 7 7.49429 7 8.10146V20.8988C7 21.5076 7.49291 22 8.09873 22H22.5996C23.206 22 23.6982 21.508 23.6986 20.8981L23.7066 9.29437C23.7071 8.68551 23.214 8.19143 22.6086 8.19143H13.5673C13.4843 8.19143 13.293 8.11973 13.2315 8.06587L12.529 7.4513C12.2269 7.18711 11.7276 7.00015 11.3268 7.00015ZM8.3183 10.7725V8.32361L11.3268 8.32335C11.4098 8.32335 11.6015 8.39521 11.6631 8.44891L12.3656 9.06349C12.6673 9.32737 13.1661 9.51479 13.5674 9.51479H22.3882L22.3873 10.7725H8.3183ZM8.3183 12.1198V20.6766H22.3805L22.3864 12.1198H8.3183ZM14.9013 19.469C15.1574 19.7305 15.5714 19.7302 15.8278 19.4657L18.0218 17.205C18.6452 16.5796 17.7098 15.6411 17.0863 16.2667L16.3274 17.0308C16.1545 17.2048 16.0144 17.1458 16.0144 16.9018V13.8595C16.0144 13.4939 15.7176 13.1975 15.353 13.1975C14.9878 13.1975 14.6917 13.4959 14.6917 13.8595V16.9018C14.6917 17.147 14.5515 17.2047 14.3787 17.0308L13.6199 16.2667C12.9962 15.6411 12.061 16.5795 12.6844 17.205L14.9013 19.469Z"
                fill="#001A72"
              />
            </svg>
          </span>
        )}
      </td>
    </tr>
  );
};
