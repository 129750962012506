import React from "react";
import { OffcanvasUsuarios } from "../../components/offcanvas/OffcanvasUsuarios";
import { TableUsuarios } from "../../components/table/TableUsuarios";

export const UsuariosPageComponent = () => {
  const usuarios = [
    {
      nombre: "Milton",
      apellidos: "Saldías Pérez",
      correo: "msaldias@internacional.cl",
      cargo: "Jefe de Contabilidad",
      usuarios: "Jefatura",
      id: 1,
    },
    {
      nombre: "Marcelo",
      apellidos: "Ayara Jara",
      correo: "maraya@internacional.cl",
      cargo: "Gerente de Contabilidad",
      usuarios: "Gerente",
      id: 2,
    },
    {
      nombre: "Carlos",
      apellidos: "García Ayala",
      correo: "maraya@internacional.cl",
      cargo: "Analista Contable",
      usuarios: "Analista",
      id: 3,
    },
    {
      nombre: "Marcelo",
      apellidos: "Ayara Jara",
      correo: "maraya@internacional.cl",
      cargo: "Gerente de Contabilidad",
      usuarios: "Gerente",
      id: 4,
    },
    {
      nombre: "Carlos",
      apellidos: "García Ayala",
      correo: "maraya@internacional.cl",
      cargo: "Analista Contable",
      usuarios: "Analista",
      id: 5,
    },
  ];
  return (
    <main className="container-fluid">
      <h1>Usuarios</h1>
      <TableUsuarios usuarios={usuarios} />
      <OffcanvasUsuarios />
    </main>
  );
};
