import React from "react";
import { TableMantenedorRelacionEntreCuentas } from "../../components/table/TableMantenedorRelacionEntreCuentas";
import { OffcanvasMantenedorBorrarRelacionEntreCuentas } from "../../components/offcanvas/offcanvasMantenedor/OffcanvasMantenedorBorrarRelacionEntreCuentas";
import { OffcanvasMantenedorCrearRelacionEntreCuentas } from "../../components/offcanvas/offcanvasMantenedor/OffcanvasMantenedorCrearRelacionEntreCuentas";
import { OffcanvasMantenedorEditarRelacionEntreCuentas } from "../../components/offcanvas/offcanvasMantenedor/OffcanvasMantenedorEditarRelacionEntreCuentas";
import { PageNavigation } from "../../components/pageNavigation/PageNavigation";

export const MantenedorRelacionEntreCuentas = () => {
  const relaciones = [
    {
      numeroCuenta: "1100101000100000",
      entidad: "Banco",
      MB1: "105000101",
      MR1: "",
      MC1: "",
      descripcion: "Efectivo en oficinas",
    },
    {
      numeroCuenta: "1100101000200000",
      entidad: "Banco",
      MB1: "105000101",
      MR1: "",
      MC1: "",
      descripcion: "Efectivo en oficinas",
    },
    {
      numeroCuenta: "1100101000300000",
      entidad: "Banco",
      MB1: "105000101",
      MR1: "",
      MC1: "",
      descripcion: "Efectivo en oficinas",
    },
    {
      numeroCuenta: "4100101000100000",
      entidad: "Banco",
      MB1: "",
      MR1: "411150100",
      MC1: "",
      descripcion: "Efectivo en empresas transportadoras en valores",
    },
    {
      numeroCuenta: "4100201000100000",
      entidad: "Banco",
      MB1: "",
      MR1: "411250102",
      MC1: "",
      descripcion: "Efectivo en empresas transportadoras en valores",
    },
    ];
  return (
    <main className="container-fluid">
      <h1>Mantenedor entre cuentas contables</h1>
      <TableMantenedorRelacionEntreCuentas relaciones={relaciones} />
      <PageNavigation />
      <OffcanvasMantenedorCrearRelacionEntreCuentas />
      <OffcanvasMantenedorEditarRelacionEntreCuentas />
      <OffcanvasMantenedorBorrarRelacionEntreCuentas />
    </main>
  );
};
