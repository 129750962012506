import React, { useState } from 'react'

export const AcceptBodyCuadratura = (props) => {
    const [dataOk, setDataOk] = props.dataState
    return (
        <>
            <div className="offcanvas-body">
                <div className="row">
                    <div className="col-12">
                    <div className="card">
                            <div className="row">
                                <div className="col-12 text-center pt-5 pb-5" >
                                    <span>
                                        <svg width="59" height="60" viewBox="0 0 59 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M23.9643 38.25C24.2357 38.5312 24.5585 38.7544 24.9142 38.9067C25.2699 39.059 25.6514 39.1374 26.0368 39.1374C26.4221 39.1374 26.8036 39.059 27.1593 38.9067C27.515 38.7544 27.8378 38.5312 28.1092 38.25L40.0184 26.01C40.568 25.4451 40.8768 24.6789 40.8768 23.88C40.8768 23.0811 40.568 22.3149 40.0184 21.75C39.4687 21.1851 38.7233 20.8677 37.9459 20.8677C37.1686 20.8677 36.4232 21.1851 35.8735 21.75L26.0368 31.89L22.5049 28.23C21.9552 27.6651 21.2097 27.3477 20.4324 27.3477C19.6551 27.3477 18.9096 27.6651 18.36 28.23C17.8104 28.7949 17.5016 29.5611 17.5016 30.36C17.5016 31.1589 17.8104 31.9251 18.36 32.49L23.9643 38.25ZM55.4595 0H2.91892C2.14477 0 1.40233 0.31607 0.854931 0.87868C0.307528 1.44129 0 2.20435 0 3V57C0 57.7957 0.307528 58.5587 0.854931 59.1213C1.40233 59.6839 2.14477 60 2.91892 60H55.4595C56.2336 60 56.976 59.6839 57.5234 59.1213C58.0709 58.5587 58.3784 57.7957 58.3784 57V3C58.3784 2.20435 58.0709 1.44129 57.5234 0.87868C56.976 0.31607 56.2336 0 55.4595 0ZM52.5405 54H5.83784V6H52.5405V54Z" fill="#14A9A0" />
                                        </svg>
                                    </span>
                                    <h5 className='fw-semibold text-turquoise pt-4'>El ajuste es correcto</h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center pt-3">
                                    <h5 className='fw-bold'>$14,000,000</h5>
                                    <p className='text-muted'>Activo</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center pt-3">
                                    <h5 className='fw-bold'>$4,000,000</h5>
                                    <p className='text-muted'>Pasivo</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center pt-3">
                                    <h5 className='fw-bold'>$10,000,000</h5>
                                    <p className='text-muted'>Patrimonio</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center pt-3">
                                    <h5 className='fw-bold'>-</h5>
                                    <p className='text-muted'>Diferencia</p>
                                </div>
                            </div>
                            <div className="row mt-5 mb-5">
                                <div className="col-12 pb-2 text-center">
                                    <button className='btn btn-primary' onClick={()=>setDataOk({isOk:2})}>Aceptar</button>
                                </div>
                                <div className="col-12 pt-2 text-center">
                                    <a className='text-button btn' itemType='button' onClick={()=>setDataOk({isOk:0})}>Cancelar</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
