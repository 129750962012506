import { tab } from "@testing-library/user-event/dist/tab";
import { Formik, useFormik } from "formik";
import React, { useId, useState } from "react";
import { AsientosConsolidacionComponentTableRow } from "./AsientosConsolidacionComponentTableRow";

export const AsientosConsolidacionComponentTable = (props) => {
  const { tablaAsientoContable } = props;
  const [tablaAsientos, setTablaAsientos] = useState(tablaAsientoContable)
  const [editar, setEditar] = useState(false);
  const [rowId, setRowId] = useState(useId());
  let totalDebe = 0;
  let totalHaber = 0;
  tablaAsientos.filas.forEach((tabla) => {
    if (tabla.debe) {
      totalDebe += parseInt(tabla.debe.replaceAll(",", ""));
    }
    if (tabla.haber) {
      totalHaber += parseInt(tabla.haber.replaceAll(",", ""));
    }
  });
  console.log(tablaAsientos)
  const initialValues = {
    empresa: "1",
    cuenta: "1",
    ifrs: "1",
    descripcion: "1",
    debe: "1",
    haber: "1",
    ajuste: "1",
    glosa: "1",
  };
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });
  const handleAddRow = () => {
    setEditar(true);
  };
  const handleSaveRow = ({ tableId, filas }) => { 
    const newRow = {
      empresa: formik.values.empresa,
      cuenta: formik.values.cuenta,
      ifrs: formik.values.ifrs,
      descripcion: formik.values.descripcion,
      debe: formik.values.debe,
      haber: formik.values.haber,
      ajuste: formik.values.ajuste,
      glosa: formik.values.glosa,
      rowId: tableId,
    }
    setTablaAsientos({filas:[...tablaAsientos.filas, newRow], tableId})
    setEditar(false)
  }
  return (
    <div className="col-12 mb-5 table-asientos-wrapper">
      <table
        className="bi-table table-asientos"
        id={tablaAsientos.tableId}
      >
        <thead>
          <tr>
            <th>Empresa</th>
            <th>Cuenta</th>
            <th>IFRS</th>
            <th>Descripcion</th>
            <th>Debe</th>
            <th>Haber</th>
            <th>Ajuste</th>
            <th>Glosa</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {tablaAsientos.filas.map((asientoContable, index) => (
            <AsientosConsolidacionComponentTableRow
              asientoContable={asientoContable}
              key={asientoContable.rowId}
            />
          ))}
          {editar && (
            <tr id={rowId}>
              <td>
                <p>
                  <input
                    type="text"
                    className="form-control"
                    id="empresa"
                    onChange={formik.handleChange}
                    value={formik.values.empresa}
                  />
                </p>
              </td>
              <td>
                <p>
                  <input
                    type="text"
                    className="form-control"
                    id="cuenta"
                    onChange={formik.handleChange}
                    value={formik.values.cuenta}
                  />
                </p>
              </td>
              <td>
                <p>
                  <input
                    type="text"
                    className="form-control"
                    id="ifrs"
                    onChange={formik.handleChange}
                    value={formik.values.ifrs}
                  />
                </p>
              </td>
              <td>
                <p>
                  <input
                    type="text"
                    className="form-control"
                    id="descripcion"
                    onChange={formik.handleChange}
                    value={formik.values.descripcion}
                  />
                </p>
              </td>
              <td>
                <p>
                  <input
                    type="text"
                    className="form-control"
                    id="debe"
                    onChange={formik.handleChange}
                    value={formik.values.debe}
                  />
                </p>
              </td>
              <td>
                <p>
                  <input
                    type="text"
                    className="form-control"
                    id="haber"
                    onChange={formik.handleChange}
                    value={formik.values.haber}
                  />
                </p>
              </td>
              <td>
                <p>
                  <input
                    type="text"
                    className="form-control"
                    id="ajuste"
                    onChange={formik.handleChange}
                    value={formik.values.ajuste}
                  />
                </p>
              </td>
              <td>
                <p>
                  <input
                    type="text"
                    className="form-control"
                    id="glosa"
                    onChange={Formik.handleChange}
                    value={formik.values.glosa}
                  />
                </p>
              </td>
              <td>
                <span className="bi-table-accion bi-table-accion-edit" onClick={handleSaveRow}>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="15"
                      cy="15"
                      r="14"
                      fill="white"
                      stroke="#001A72"
                      strokeWidth="2"
                    />
                    <path
                      d="M22.5 11.43C22.5006 11.3313 22.4816 11.2335 22.4443 11.1421C22.407 11.0507 22.352 10.9676 22.2825 10.8975L19.1025 7.71751C19.0324 7.648 18.9493 7.59301 18.8579 7.55568C18.7665 7.51836 18.6687 7.49944 18.57 7.50001C18.4713 7.49944 18.3734 7.51836 18.2821 7.55568C18.1907 7.59301 18.1076 7.648 18.0375 7.71751L15.915 9.84001L7.71751 18.0375C7.648 18.1076 7.59301 18.1907 7.55568 18.2821C7.51836 18.3734 7.49944 18.4713 7.50001 18.57V21.75C7.50001 21.9489 7.57903 22.1397 7.71968 22.2803C7.86033 22.421 8.0511 22.5 8.25001 22.5H11.43C11.535 22.5057 11.6399 22.4893 11.7381 22.4518C11.8363 22.4143 11.9255 22.3567 12 22.2825L20.1525 14.085L22.2825 12C22.3509 11.9273 22.4067 11.8437 22.4475 11.7525C22.4547 11.6927 22.4547 11.6323 22.4475 11.5725C22.451 11.5376 22.451 11.5024 22.4475 11.4675L22.5 11.43ZM11.1225 21H9.00001V18.8775L16.4475 11.43L18.57 13.5525L11.1225 21ZM19.6275 12.495L17.505 10.3725L18.57 9.31501L20.685 11.43L19.6275 12.495Z"
                      fill="#001A72"
                    />
                  </svg>
                </span>
              </td>
            </tr>
          )}
          <tr className="table-asientos-total">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="table-asientos-totales">
              <p>{totalDebe == 0 ? "-" : totalDebe.toLocaleString()}</p>
            </td>
            <td className="table-asientos-totales">
              <p>{totalHaber == 0 ? "-" : totalDebe.toLocaleString()}</p>
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <button
        type="button"
        className="table-asientos-add-row"
        onClick={() => {
          handleAddRow(tablaAsientos);
        }}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="#6E7682"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18ZM14 9H11V6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5C9.73479 5 9.48043 5.10536 9.2929 5.29289C9.10536 5.48043 9 5.73478 9 6V9H6C5.73479 9 5.48043 9.10536 5.2929 9.29289C5.10536 9.48043 5 9.73478 5 10C5 10.2652 5.10536 10.5196 5.2929 10.7071C5.48043 10.8946 5.73479 11 6 11H9V14C9 14.2652 9.10536 14.5196 9.2929 14.7071C9.48043 14.8946 9.73479 15 10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14V11H14C14.2652 11 14.5196 10.8946 14.7071 10.7071C14.8946 10.5196 15 10.2652 15 10C15 9.73478 14.8946 9.48043 14.7071 9.29289C14.5196 9.10536 14.2652 9 14 9Z"
            fill="#6E7682"
          />
        </svg>
      </button>
    </div>
  );
};
