import React from "react";

export const TableReportesMC1 = () => {
  const reportes = [
    "0009MC1202210",
    "81100000000196241744191+",
    "81110000000000000000000+",
    "81110010000000000000000+",
    "81110020000000000000000+",
    "81120000000194646748146+",
    "81120010000169695133826+",
    "81120010100169695133826+",
    "81120010200000000000000+",
    "81120010300000000000000+",
    "81120010400000000000000+",
    "81120060000001134307880+",
    "81120070000001168594038+",
    "81120080000000000000000+",
    "81120090000000555410838+",
    "81120100000014558051009+",
    "81120110000007481038842+",
    "81120120000000054211713+",
    "81130000000001346999564+",
    "81130010000000369497308+",
    "81130020000000730568866+",
    "81130030000000000000000+",
    "81130040000000246933390+",
    "81130050000000000000000+",
    "81130060000000000000000+",
    "81140000000000247996481+",
    "81140010000000237209042+",
    "81140020000000010453589+",
    "81140030000000000251975+",
    "81140040000000000000000+",
    "81140050000000000081875+",
    "81200000000087756447417+",
    "81210000000000000000000+",
    "81210010000000000000000+",
    "81210020000000000000000+",
    "81220000000081596465749+",
    "81220010000068467281318+",
    "81220010100068388947197+",
    "81220010200000000000000+",
    "81220010300000078334121+",
    "81220010400000000000000+",
    "81220060000000416103090+",
    "81220070000000125641970+",
    "81220080000000000000000+",
    "81220090000000000000000+",
  ];
  return (
    <div className="table-reportes">
    <div className="table-reportes-id">
      {reportes.map((reporte) => ( <p> {reporte}</p>))}
    </div>
    <div className="table-reportes-numero">
      <span>Caracteres</span>
      {reportes.map((reporte) => ( <span>{reporte.length}</span>))}
    </div>
  </div>
  );
};
