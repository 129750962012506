import React from 'react'
import { useFormik } from "formik";
import { AcceptBodyCuadratura } from '../AcceptBodyCuadratura/AcceptBodyCuadratura';
import { DeniedBodyCuadratura } from '../DeniedBodyCuadratura/DeniedBodyCuadratura';
import { SuccesBodyCuadratura } from '../SuccesBodyCuadratura/SuccessBodyCuadratura';

export const BodyOffcanvasEditCuadratura = (props) => {
  const [dataOk, setDataOk] = props.dataState

    if(dataOk.isOk === 1){
        console.log('dataOk.isOk === 1');
        return (
            <AcceptBodyCuadratura dataState={[dataOk, setDataOk]}/>
        )
    }
    if(dataOk.isOk === 2){
        console.log('dataOk.isOk === 2');
        return (
            <SuccesBodyCuadratura dataState={[dataOk, setDataOk]}/>
        )
    }
    else if(dataOk.isOk === 0){
        console.log('dataOk.isOk === 0');
        return (
            <DeniedBodyCuadratura dataState={[dataOk, setDataOk]}/>
        )
    }
  const initialValues = {
    cuentaIFRS: "",
    tipoMoneda: "",
    ajuste: "-",
  };
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });
  return (
      <div className="offcanvas-body">
        <div className="row">
          <div className="col-12 mb-4">
            <div className="form-floating">
              <select
                className="form-select"
                id="inputCuentaIFRS"
                aria-label="Cuenta IFRS"
              >
                <option>Selecciona Opción</option>
                <option defaultValue value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
              <label htmlFor="inputTipoCuenta">Cuenta IFRS</label>
            </div>
          </div>
          <div className="col-12 mb-4">
            <div className="form-floating">
              <select
                className="form-select"
                id="inputTipoMoneda"
                aria-label="Tipo de moneda"
              >
                <option>Selecciona Opción</option>
                <option defaultValue value="peso">Peso</option>
                <option value="moneda2">moneda2</option>
                <option value="moneda3">moneda3</option>
              </select>
              <label htmlFor="inputSignoAsociado">Tipo de moneda</label>
            </div>
          </div>
          <div className="col-12 mb-4">
            <div className="form-floating">
              <input type="text" className="form-control" id="ajuste" onChange={formik.handleChange} value={formik.values.ajuste}/>
              <label htmlFor="inputCodigo">Ajuste</label>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <a
                href="#"
                className="btn btn-secondary"
                onClick={() => { setDataOk({isOk:0}) }}
              >
                Cancelar
              </a>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <a
                href="#"
                className="btn btn-primary"
                onClick={() => { setDataOk({isOk:1}) }}
              >
                Ajustar
              </a>
            </div>
          </div>
        </div>
      </div>
  );
}
